import utils from "../../../utils";

import store from "../../../store";
import util from "store/src/util";

export default {
	dataMenu: {
		dataMenuList: [{
				name: "报警查询",
				title: "报警查询",
				path: "alarmQuery/",
				menuId: "data_center_alarmquery",
				icon: "icon-menu iconalert_cbaojingc iconfont",
				children: [{
						name: "主动安全报警明细",
						title: "主动安全报警明细",
						path: "/activeSecalarm",
						routerName: "activeSecalarm",
						parent: "dataManage",
						menuId: "data_center_alarmquery_asdetail"
					}, 
					{
						name: "常规报警明细",
						title: "常规报警明细",
						path: "/alarmdetail",
						routerName: "alarmdetail",
						parent: "dataManage",
						menuId: "data_center_alarmquery_detail"
					},
					{
						name: "AEB报警明细",
						title: "AEB报警明细",
						path: "/alarmaeb",
						routerName: "alarmaeb",
						parent: "dataManage",
						menuId: "data_center_alarmquery_aebdetail"
					},					
					{
						name: "下级平台掉线提醒",
						title: "下级平台掉线提醒",
						path: "/firmofflinewarn",
						routerName: "firmofflinewarn",
						parent: "dataManage",
						menuId: "data_center_alarmquery_offlineplat"
					},
					{
						name: "车辆掉线提醒",
						title: "车辆掉线提醒",
						path: "/vehofflinewarn",
						routerName: "vehofflinewarn",
						parent: "dataManage",
						menuId: "data_center_alarmquery_offlineveh"
					}			
				]
			},
			{
				name: "报警报表",
				title: "报警报表",
				path: "alarmDetails/",
				menuId: "data_center_alarmstat",
				icon: "icon-menu iconfont iconbaojingjilu-copy",
				children: [{
					name: "车辆日报警明细",
					title: "车辆日报警明细",
					path: "/alarmday",
					routerName: "alarmday",
					menuId: "data_center_alarmstat_day",
					parent: "dataManage"
				}, {
					name: "超速报警月统计",
					title: "超速报警月统计",
					path: "/speedalarmmonth",
					routerName: "speedalarmmonth",
					parent: "dataManage",
					menuId: "data_center_speed_alarm_month"
				}, {
					name: "超时驾驶月统计",
					title: "超时驾驶月统计",
					path: "/fatiguedrivemonth",
					routerName: "fatiguedrivemonth",
					parent: "dataManage",
					menuId: "data_center_overtime_alarm_month"
				}, {
					name: "超速报警日统计",
					title: "超速报警日统计",
					path: "/speedalarm",
					routerName: "speedalarm",
					parent: "dataManage",
					menuId: "data_center_alarmstat_speed_alarm"
				}, {
					name: "超时驾驶日统计",
					title: "超时驾驶日统计",
					path: "/fatiguedriver",
					routerName: "fatiguedriver",
					parent: "dataManage",
					menuId: "data_center_alarmstat_overtime_alarm"
				}, {
					name: "超速报警明细",
					title: "超速报警明细",
					path: "/speeddayalarm",
					routerName: "speeddayalarm",
					parent: "dataManage",
					menuId: "data_center_speed_alarm"
				}, {
					name: "超时驾驶明细",
					title: "超时驾驶明细",
					path: "/fatiguedaydriver",
					routerName: "fatiguedaydriver",
					parent: "dataManage",
					menuId: "data_center_fatigue_driver"
				}, {
					name: "三方日报警明细",
					title: "三方日报警明细",
					path: "/thirdparty",
					routerName: "thirdparty",
					parent: "dataManage",
					menuId: "data_center_alarmstat_thirdparty"
				}, {
					name: "广东日报警明细",
					title: "广东日报警明细",
					path: "/guangdongalarm",
					routerName: "guangdongalarm",
					parent: "dataManage",
					menuId: "data_center_alarmstat_guangdongalarm"
				},{
					name: "车辆动态监控值班记录表",
					title: "车辆动态监控值班记录表",
					path: "/handover",
					routerName: "handover",
					parent: "dataManage",
					menuId: "data_center_handover",
				},{
					name: "车辆月报警统计",
					title: "车辆月报警统计",
					path: "/staticByMonth",
					routerName: "staticByMonth",
					parent: "dataManage",
					menuId: "data_center_alarmstat_staticByMonth",
				}]
			},
			{
				name: "里程报表",
				title: "里程报表",
				path: "/mileageReport",
				menuId: "data_center_mileage",
				icon: "icon-menu iconlicheng iconfont",
				children: [{
					name: "里程日报",
					title: "里程日报",
					path: "/mileageDaily",
					routerName: "mileageDaily",
					menuId: "data_center_mileage_dayrep",
					parent: "dataManage"
				}, {
					name: "里程月报",
					title: "里程月报",
					path: "/mileageMonthly",
					routerName: "mileageMonthly",
					menuId: "data_center_mileage_monthrep",
					parent: "dataManage"
				}, {
					name: "收割机里程",
					title: "收割机里程",
					path: "/mileageHarvester",
					routerName: "mileageHarvester",
					menuId: "data_center_mileage_harvester",
					parent: "dataManage"
				}]
			},
			{
				name: "定位报表",
				title: "定位报表",
				path: "/locationReport",
				menuId: "data_center_positioning",
				icon: "icon-menu iconfont iconquyu",
				children: [{
					name: "车辆轨迹报表",
					title: "车辆轨迹报表",
					path: "/vehicleTrack",
					routerName: "vehicleTrack",
					menuId: "data_center_positioning_locus",
					parent: "dataManage"
				}, {
					name: "长时间未定位查询",
					title: "长时间未定位查询",
					path: "/offlineStatistics",
					routerName: "offlineStatistics",
					menuId: "data_center_positioning_offline",
					parent: "dataManage"
				}]
			},
			{
				name: "车辆状态报表",
				title: "车辆状态报表",
				path: "statusDetails/",
				menuId: "data_center_state",
				icon: "icon-menu iconfont iconB",
				children: [{
					name: "车辆运行统计",
					title: "车辆运行统计",
					path: "/vehicleonoffstatic",
					routerName: "vehicleonoffstatic",
					menuId: "data_center_state_updownline",
					parent: "dataManage"
				}, {
					name: "车辆运行明细",
					title: "车辆运行明细",
					path: "/vehicleonoffdetail",
					routerName: "vehicleonoffdetail",
					menuId: "data_center_state_updownlinedetail",
					parent: "dataManage"
				}, {
					name: "车辆离线查询",
					title: "车辆离线查询",
					path: "/offlinevehiclelist",
					routerName: "offlinevehiclelist",
					menuId: "data_center_state_vehicleofflinequery",
					parent: "dataManage"
				}, {
					name: "从未上线车辆统计",
					title: "从未上线车辆统计",
					path: "/vehicleofflinestatic",
					routerName: "vehicleofflinestatic",
					menuId: "data_center_state_noposition",
					parent: "dataManage"
				}, {
					name: "车辆月在线统计",
					title: "车辆月在线统计",
					path: "/vehiclemonthstatic",
					routerName: "vehiclemonthstatic",
					menuId: "data_center_state_vehmonthonline",
					parent: "dataManage"
				}, {
					name: "企业月在线统计",
					title: "企业月在线统计",
					path: "/companymonthstatic",
					routerName: "companymonthstatic",
					menuId: "data_center_state_entmonthonline",
					parent: "dataManage"
				}]
			},
			{
				name: "车辆行驶报表",
				title: "车辆行驶报表",
				path: "drivingDetail/",
				menuId: "data_center_travel",
				icon: "icon-menu iconfont iconjiashicang",
				children: [{
					name: "行车明细统计",
					title: "行车明细统计",
					path: "/drivingstatic",
					routerName: "drivingstatic",
					menuId: "data_center_travel_drivedetail",
					parent: "dataManage"
				}, {
					name: "停车明细统计",
					title: "停车明细统计",
					path: "/parkingstatic",
					routerName: "parkingstatic",
					menuId: "data_center_travel_parkingdetail",
					parent: "dataManage"
				}, {
					name: "行车汇总统计",
					title: "行车汇总统计",
					path: "/driveCollect",
					routerName: "driveCollect",
					menuId: "data_center_travel_driving",
					parent: "dataManage"
				}, {
					name: "停车汇总统计",
					title: "停车汇总统计",
					path: "/parkCollect",
					routerName: "parkCollect",
					menuId: "data_center_travel_parking",
					parent: "dataManage"
				}]
			},
			{
				name: "驾驶员行驶报表",
				title: "驾驶员行驶报表",
				path: "/driverDetail",
				menuId: "data_center_driver_detail",
				icon: "icon-menu iconpeizhisiji iconfont",
				children: [{
					name: "驾驶员刷卡记录",
					title: "驾驶员刷卡记录",
					path: "/drivercardrecord",
					routerName: "drivercardrecord",
					menuId: "data_center_driver_card_record",
					parent: "dataManage"
				}]
			},
			{
				name: "监管考核",
				title: "监管考核",
				path: "/superviseAssess",
				menuId: "data_center_supassess",
				icon: "icon-menu iconkaohejilu iconfont",
				children: [{
						name: "车辆上线率统计",
						title: "车辆上线率统计",
						path: "/vehicleonline",
						routerName: "vehicleonline",
						menuId: "data_center_supassess_onlinerate",
						parent: "dataManage"
					}, {
						name: "企业车辆在线统计",
						title: "企业车辆在线统计",
						path: "/comVehicleonline",
						routerName: "comVehicleonline",
						menuId: "data_center_state_entmonthonline",
						parent: "dataManage"
					}, {
						name: "轨迹完整率",
						title: "轨迹完整率",
						path: "/trackrate",
						routerName: "trackrate",
						menuId: "data_center_supassess_trailrate",
						parent: "dataManage"
					}, {
						name: "企业入网率",
						title: "企业入网率",
						path: "/accessrate",
						routerName: "accessrate",
						menuId: "data_center_supassess_accessrate",
						parent: "dataManage"
					}, {
						name: "车辆数据合格率",
						title: "车辆数据合格率",
						path: "/datapassrate",
						routerName: "datapassrate",
						menuId: "data_center_supassess_datapassrate",
						parent: "dataManage"
					}, {
						name: "企业漂移率",
						title: "企业漂移率",
						path: "/driftrate",
						routerName: "driftrate",
						menuId: "data_center_supassess_driftrate",
						parent: "dataManage"
					}
					// {
					//     name: "平台连通率",
					//     title: "平台连通率",
					//     path: "/connectrate",
					//     routerName: "connectrate",
					//     menuId: "data_center_supassess_connectrate",
					//     parent: "dataManage"
					// }
				]
			},
			{
				name: "监管记录",
				title: "监管记录",
				path: "/otherReport",
				menuId: "data_center_regulatory",
				icon: "icon-menu iconqita iconfont",
				children: [{
					name: "查岗通知明细",
					title: "查岗通知明细",
					path: "/inspectionNotice",
					routerName: "inspectionNotice",
					menuId: "data_center_regulatory_chagang",
					parent: "dataManage"
				}]
			},
			{
				name: "多媒体查询",
				title: "多媒体查询",
				path: "/multimediaQuery",
				menuId: "data_center_multimedia",
				icon: "icon-menu iconduomeiti iconfont",
				children: [{
					name: "主动安全",
					title: "主动安全",
					path: "/activeSafe",
					routerName: "activeSafe",
					menuId: "data_center_multimedia_activeSafe",
					parent: "dataManage"
				}, {
					name: "摄像头抓拍",
					title: "摄像头抓拍",
					path: "/instructIssue",
					routerName: "instructIssue",
					menuId: "data_center_multimedia_instruct",
					parent: "dataManage"
				}]
			},
			{
				name: "分析报告",
				title: "分析报告",
				path: "/analyzeReport",
				menuId: "data_center_analysis",
				icon: "icon-menu icontongjifenxi-copy iconfont",
				children: [{
					name: "企业风险分析",
					title: "企业风险",
					path: "/entRisk",
					routerName: "entRisk",
					menuId: "data_center_analysis_ent",
					parent: "dataManage"
					}, {
						name: "驾驶员风险分析",
						title: "视频查询",
						path: "/driverRisk",
						routerName: "driverRisk",
						menuId: "data_center_analysis_driver",
						parent: "dataManage"
					}, {
						name: "车辆风险分析",
						title: "车辆风险分析",
						path: "/vehicleRisk",
						routerName: "vehicleRisk",
						menuId: "data_center_analysis_vehicle",
						parent: "dataManage"
					}, 
					{
						name: "企业分析报告",
						title: "企业分析报告",
						path: "/safeWebRisk",
						routerName: "safeWebRisk",
						menuId: "data_center_analysis_safewebrisk",
						parent: "dataManage"
					}
				]
			},
			{
				name: "下发记录",
				title: "下发记录",
				path: "/issuedQuery",
				menuId: "data_center_issuerecord",
				icon: "icon-menu iconxiafa iconfont",
				children: [{
					name: "通知记录",
					title: "通知记录",
					path: "/smsissued",
					routerName: "smsissued",
					menuId: "data_center_issuerecord_notice",
					parent: "dataManage"
				}]
			},
			{
				name: "企业报表",
				title: "企业报表",
				path: "/companyQuery",
				menuId: "data_center_companyquery",
				icon: "icon-menu iconbaobiao-copy iconfont",
				children: [{
					name: "企业月分析报告",
					title: "企业月分析报告",
					path: "/companyMonthReport",
					routerName: "companyMonthReport",
					menuId: "data_center_companyquery_monthreport",
					parent: "dataManage"
				}, {
					name: "企业月报警统计",
					title: "企业月报警统计",
					path: "/companyMonthAlarm",
					routerName: "companyMonthAlarm",
					menuId: "data_center_companyquery_monthalarm",
					parent: "dataManage"
				}]
			}
		]
	},
	alarmDetailManage: {
		formInput: [
		{
		    label: "组织名称:",
		    type: "orgTree",
		    key: "entName",
		    itemType: "filterItem",
		    style: {
		        width: "30%"
		    }
		},
		{
		    label: "车牌号码:",
		    type: "searchinputv2",
		    key: "vid",
		    style: {
		        width: "20%"
		    },
			options: []
		}, {
			label: "报警类型:",
			type: "alarmTree",
			key: "alarmCode",
			readonly: "readonly",
			category: 0,
			style: {
				width: "20%"
			}
		}, {
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			style: {
				width: "30%"
			},
			handler: "startEndTime"
		},{
			label: "持续时长:",
			type: "time",
			popperClass: "monitor-date-picker",
			key: "durationTime",
			style: {
				width: "30%"
			},
			handler: "durationTime"
		}, {
			label: "处理状态",
			type: "select",
			popperClass: "monitor-select",
			key: "handleStatus",
			options: [{
				label: "全部",
				value: ""
			}, {
				label: "人工待处理",
				value: "0"
			}, {
				label: "人工已处理",
				value: "1"
			}, {
				label: "AI处理",
				value: "2"
			}, {
				label: "误报",
				value: "3"
			}, {
				label: "忽略",
				value: "4"
			}, {
				label: "核警",
				value: "5"
			}],
			style: {
				width: "20%"
			}
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				minWidth: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				minWidth: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "报警类型",
				prop: "alarmCode",
				minWidth: utils.setFontSize(150),
				render(row) {
					let allAlarmType = store.state.allAlarmType;
					return allAlarmType[row.alarmCode] || "--";
				}
			},
			{
				label: "速度",
				prop: "speed",
				minWidth: utils.setFontSize(100),
				render(row) {
					return (row.speed || row.speed === 0) ? parseFloat((row.speed / 10).toFixed(1)) + " km/h" : "--";
				}
			},
			{
				label: "报警来源",
				prop: "alarmSource",
				minWidth: utils.setFontSize(120),
				render(row) {
					let alarmSource = {
						1: "终端报警",
						2: "平台报警",
						3: "下级平台报警"
					};
					return alarmSource[row.alarmSource] || "--";
				}
			},
			{
				label: "报警开始时间",
				prop: "startTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "报警结束时间",
				prop: "endTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "报警位置",
				prop: "address",
				minWidth: utils.setFontSize(200),
				tooltip:true,
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "持续时长",
				prop: "duration",
				minWidth: utils.setFontSize(120),
				render(row) {
					let dayDuration = row.endTime - row.startTime;
					return utils.getDuration(dayDuration) || "--";
				}
			},
			{
				label: "持续里程(km)",
				prop: "licheng",
				minWidth: utils.setFontSize(120),
				render(row) {
					if (row.endMileage && row.startMileage && row.endMileage !== 0 && row.startMileage !== 0) {
						return (row.endMileage - row.startMileage) / 10 + "";
					} else {
						return "--";
					}
				}
			},
			{
				label: "电话回执",
				prop: "phoneStatus",
				minWidth: utils.setFontSize(120)
			},
			{
				label: "处理状态",
				prop: "handleStatus",
				minWidth: utils.setFontSize(120),
				render(row) {
					let handleStatus = {
						0: "人工待处理",
						1: "人工已处理",
						2: "AI处理",
						3: "误报",
						4: "忽略",
						5: "核警"
					};
					return handleStatus[row.handleStatus] || "--";
				}
			},
			{
				label: "处理方式",
				prop: "handleWay",
				minWidth: utils.setFontSize(120),
				render(row) {
					let handleWay = {
						1: "终端提醒",
						2: "短信提醒",
						3: "电话提醒"
					};
					return handleWay[row.handleWay] || "--";
				}
			},
			{
				label: "处理人",
				prop: "handlePeople",
				minWidth: utils.setFontSize(100),
				render(row) {
					return row.handlePeople || "--";
				}
			},
			{
				label: "处理时间",
				prop: "handleTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.handleTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "处理内容",
				prop: "handleContent",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handleContent || "--";
				}
			},
			{
				label: "处理描述",
				prop: "handleRemark",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handleRemark || "--";
				}
			},
			{
				label: "证据",
				prop: "photoShow",
				minWidth: utils.setFontSize(120)
			}
		]
	},
	alarmAebManage: {
		formInput: [
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "30%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},		
		{
			label: "报警类型:",
			type: "select",
			key: "alarmCodeList",
			collapseTags: true,
			multiple: true,
			readonly: true,
			style: {
				width: "20%"
			},
			options: []
		},
		{
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			style: {
				width: "30%"
			},
			handler: "startEndTime"
		},{
			label: "持续时长:",
			type: "time",
			popperClass: "monitor-date-picker",
			key: "durationTime",
			style: {
				width: "30%"
			},
			handler: "durationTime"
		}, {
			label: "处理状态:",
			type: "select",
			popperClass: "monitor-select",
			key: "handleStatus",
			style: {
				width: "20%"
			},			
			options: [{
				label: "全部",
				value: ""
			}, {
				label: "人工待处理",
				value: "0"
			}, {
				label: "人工已处理",
				value: "1"
			}, {
				label: "AI处理",
				value: "2"
			}, {
				label: "误报",
				value: "3"
			}, {
				label: "忽略",
				value: "4"
			}, {
				label: "核警",
				value: "5"
			}],
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				minWidth: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				minWidth: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "报警类型",
				prop: "alarmCode",
				minWidth: utils.setFontSize(180),
				render(row) {
					let allAlarmType = store.state.allAlarmType;
					return allAlarmType[row.alarmCode] || "--";
				}
			},
			{
				label: "速度",
				prop: "speed",
				minWidth: utils.setFontSize(100),
				render(row) {
					return (row.speed || row.speed === 0) ? parseFloat((row.speed / 10).toFixed(1)) + " km/h" : "--";
				}
			},
			{
				label: "报警级别",
				prop: "alarmLevel",
				minWidth: utils.setFontSize(100),
				render(row) {
					let alarmLevel = {
						1: "一级",
						2: "二级"
					};
					return alarmLevel[row.alarmLevel] || "--";
				}
			},
			{
				label: "报警来源",
				prop: "alarmSource",
				minWidth: utils.setFontSize(120),
				render(row) {
					let alarmSource = {
						1: "终端报警",
						2: "平台报警",
						3: "下级平台报警"
					};
					return alarmSource[row.alarmSource] || "--";
				}
			},
			{
				label: "证据",
				prop: "photoShow",
				minWidth: utils.setFontSize(120)
			},
			{
				label: "报警开始时间",
				prop: "startTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "报警结束时间",
				prop: "endTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "报警位置",
				prop: "address",
				minWidth: utils.setFontSize(200),
				tooltip:true,
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "持续里程",
				prop: "licheng",
				minWidth: utils.setFontSize(120),
				render(row) {
					if (row.endMileage && row.startMileage && row.endMileage !== 0 && row.startMileage !== 0) {
						return (row.endMileage - row.startMileage) / 10;
					} else {
						return "--";
					}
				}
			},
			{
				label: "处理方式",
				prop: "handleWay",
				minWidth: utils.setFontSize(120),
				render(row) {
					let handleWay = {
						1: "终端提醒",
						2: "短信提醒",
						3: "电话提醒"
					};
					return handleWay[row.handleWay] || "--";
				}
			},
			{
				label: "处理状态",
				prop: "handleStatus",
				minWidth: utils.setFontSize(120),
				render(row) {
					let handleStatus = {
						0: "人工待处理",
						1: "人工已处理",
						2: "AI处理",
						3: "误报",
						4: "忽略",
						5: "核警"
					};
					return handleStatus[row.handleStatus] || "--";
				}
			},
			{
				label: "处理人",
				prop: "handlePeople",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handlePeople || "--";
				}
			},
			{
				label: "处理时间",
				prop: "handleTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.handleTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "处理内容",
				prop: "handleContent",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handleContent || "--";
				}
			},
			{
				label: "处理描述",
				prop: "handleRemark",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handleRemark || "--";
				}
			}
		]
	},
	activeSecManage: {
		formInput: [
		// {
		// 	label: "组织/车辆:",
		// 	type: "vehicleTree",
		// 	key: "entName",
		// 	placeholder: "输入进行查询",
		// 	style: {
		// 		width: "calc(25% - 60px)",
		// 		marginRight: "20px"
		// 	}
		// }, 
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "30%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},		
		{
			label: "报警类型:",
			type: "alarmTree",
			key: "alarmCode",
			readonly: "readonly",
			category: 1,
			style: {
				width: "20%"
			}
		}, {
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			style: {
				width: "30%"
			},
			handler: "startEndTime"
		},{
			label: "持续时长:",
			type: "time",
			popperClass: "monitor-date-picker",
			key: "durationTime",
			style: {
				width: "30%"
			},
			handler: "durationTime"
		}, {
			label: "处理状态:",
			type: "select",
			popperClass: "monitor-select",
			key: "handleStatus",
			style: {
				width: "20%"
			},			
			options: [{
				label: "全部",
				value: ""
			}, {
				label: "人工待处理",
				value: "0"
			}, {
				label: "人工已处理",
				value: "1"
			}, {
				label: "AI处理",
				value: "2"
			}, {
				label: "误报",
				value: "3"
			}, {
				label: "忽略",
				value: "4"
			}, {
				label: "核警",
				value: "5"
			}],
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				minWidth: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				minWidth: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "报警类型",
				prop: "alarmCode",
				minWidth: utils.setFontSize(180),
				render(row) {
					let allAlarmType = store.state.allAlarmType;
					return allAlarmType[row.alarmCode] || "--";
				}
			},
			{
				label: "速度",
				prop: "speed",
				minWidth: utils.setFontSize(100),
				render(row) {
					return (row.speed || row.speed === 0) ? parseFloat((row.speed / 10).toFixed(1)) + " km/h" : "--";
				}
			},
			{
				label: "报警级别",
				prop: "alarmLevel",
				minWidth: utils.setFontSize(100),
				render(row) {
					let alarmLevel = {
						1: "一级",
						2: "二级"
					};
					return alarmLevel[row.alarmLevel] || "--";
				}
			},
			{
				label: "报警来源",
				prop: "alarmSource",
				minWidth: utils.setFontSize(120),
				render(row) {
					let alarmSource = {
						1: "终端报警",
						2: "平台报警",
						3: "下级平台报警"
					};
					return alarmSource[row.alarmSource] || "--";
				}
			},
			{
				label: "证据",
				prop: "photoShow",
				minWidth: utils.setFontSize(120)
			},
			{
				label: "报警开始时间",
				prop: "startTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "报警结束时间",
				prop: "endTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "报警位置",
				prop: "address",
				minWidth: utils.setFontSize(200),
				tooltip:true,
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "持续里程",
				prop: "licheng",
				minWidth: utils.setFontSize(120),
				render(row) {
					if (row.endMileage && row.startMileage && row.endMileage !== 0 && row.startMileage !== 0) {
						return (row.endMileage - row.startMileage) / 10;
					} else {
						return "--";
					}
				}
			},
			{
				label: "处理方式",
				prop: "handleWay",
				minWidth: utils.setFontSize(120),
				render(row) {
					let handleWay = {
						1: "终端提醒",
						2: "短信提醒",
						3: "电话提醒"
					};
					return handleWay[row.handleWay] || "--";
				}
			},
			{
				label: "处理状态",
				prop: "handleStatus",
				minWidth: utils.setFontSize(120),
				render(row) {
					let handleStatus = {
						0: "人工待处理",
						1: "人工已处理",
						2: "AI处理",
						3: "误报",
						4: "忽略",
						5: "核警"
					};
					return handleStatus[row.handleStatus] || "--";
				}
			},
			{
				label: "处理人",
				prop: "handlePeople",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handlePeople || "--";
				}
			},
			{
				label: "处理时间",
				prop: "handleTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.handleTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "处理内容",
				prop: "handleContent",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handleContent || "--";
				}
			},
			{
				label: "处理描述",
				prop: "handleRemark",
				minWidth: utils.setFontSize(120),
				render(row) {
					return row.handleRemark || "--";
				}
			}
		]
	},
	driverCardManage: {
		formInput: [
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "22%",
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "18%",
			},
			options: []
		}		
		, {
			label: "驾驶员姓名:",
			type: "input",
			key: "driverName",
			style: {
				width: "18%",
			}
		}, {
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			pickerOptions: {},
			style: {width: "28%"},
			handler: "startEndTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "驾驶员姓名",
				prop: "driverName",
				width: utils.setFontSize(100),
				render(row) {
					return row.driverName || "--";
				}
			},
			{
				label: "驾驶员身份证号",
				width: utils.setFontSize(200),
				prop: "idcard",
				render(row) {
					return row.idcard || "--";
				}
			},
			{
				label: "发证机构",
				prop: "licenceIssuingAuthority",
				width: utils.setFontSize(120),
				render(row) {
					return row.licenceIssuingAuthority || "--";
				}
			},
			{
				label: "从业资格证号",
				prop: "licenseCode",
				width: utils.setFontSize(200),
				render(row) {
					return row.licenseCode || "--";
				}
			},
			{
				label: "从业资格证有效期",
				prop: "certificateValidityExpiration",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.certificateValidityExpiration, "YYYY-MM-DD") || "--";
				}
			},
			{
				label: "读卡结果",
				prop: "icReadResult",
				minWidth: utils.setFontSize(120),
				render(row) {
					let icReadResult = {
						0: "读卡成功",
						1: "读卡失败，原因为卡片密钥认证未通过",
						2: "读卡失败，原因为卡片已被锁定",
						3: "读卡失败，原因为卡片被拔出",
						4: "读卡失败，原因为数据校验错误"
					};
					return icReadResult[row.icReadResult] || "--";
				}
			},
			{
				label: "刷卡时间",
				prop: "swipeTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.swipeTime) || "--";
				}
			},
			{
				label: "退卡时间",
				prop: "pullTime",
				minWidth: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.pullTime) || "--";
				}
			},
			{
				label: "合计时长",
				prop: "duration",
				minWidth: utils.setFontSize(150),
				render(row) {
					let duration = "--";
					if (row.swipeTime && row.pullTime) {
						let {
							hours,
							minutes,
							seconds
						} = utils.getDuration3(row.pullTime - row.swipeTime);
						duration =
							`${utils.getTimeStr(hours)}:${utils.getTimeStr(minutes)}:${utils.getTimeStr(seconds)}`;
					}
					return duration;
				}
			},
			{
				label: "刷卡位置",
				prop: "swipeAddress",
				minWidth: utils.setFontSize(250),
				render(row) {
					return row.swipeAddress || "--";
				}
			},
			{
				label: "拔卡位置",
				prop: "pullAddress",
				minWidth: utils.setFontSize(250),
				render(row) {
					return row.pullAddress || "--";
				}
			}
		]
	},
	// 超时报警月统计
	fatigueDriveMonth: {
		formInput: [
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "30%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},		
		{
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "daterange",
			style: {
				width: "30%"
			},
			handler: "startEndTime"
		}, {
			label: "数据来源:",
			type: "select",
			key: "alarmCodes",
			clearable: true,
			options: [{
				label: "全部",
				value: "2,1002"
			}, {
				label: "终端",
				value: "2"
			}, {
				label: "平台",
				value: "1002"
			}],
			width: "20%"
		}, {
			label: "持续时长:",
			type: "time",
			popperClass: "monitor-date-picker",
			key: "durationTime",
			style: {
				width: "30%"
			},
			handler: "durationTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "报警总数",
				prop: "totalAlarmNum",
				render(row) {
					return row.totalAlarmNum + "" || "--";
				}
			},
			{
				label: "持续时长",
				prop: "totalAlarmDurationTime",
				render(row) {
					return utils.getDuration(row.totalAlarmDurationTime * 1000) || "--";
				}
			},
			{
				label: "持续里程(km)",
				prop: "totalAlarmDurationMileage",
				render(row) {
					return +row.totalAlarmDurationMileage > -1 ? row.totalAlarmDurationMileage + "" : "--";
				}
			}
		]
	},
	// 超速报警月统计
	speedAlarmMonth: {
		formInput: [
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "30%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},		
		{
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "daterange",
			style: {
				width: "25%"
			},
			handler: "startEndTime"
		}, {
			label: "数据来源:",
			type: "select",
			key: "alarmCodes",
			clearable: true,
			options: [{
				label: "全部",
				value: "1,1001"
			}, {
				label: "终端",
				value: "1"
			}, {
				label: "平台",
				value: "1001"
			}],
			style: {
				width: "25%"
			}
		}, {
			label: "持续时长:",
			type: "time",
			popperClass: "monitor-date-picker",
			key: "durationTime",
			style: {
				width: "30%"
			},
			handler: "durationTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "报警总数",
				prop: "totalAlarmNum",
				render(row) {
					return row.totalAlarmNum || "--";
				}
			},
			{
				label: "持续时长",
				prop: "totalAlarmDurationTime",
				render(row) {
					return utils.getDuration(row.totalAlarmDurationTime * 1000) || "--";
				}
			},
			{
				label: "持续里程(km)",
				prop: "totalAlarmDurationMileage",
				render(row) {
					return +row.totalAlarmDurationMileage > -1 ? row.totalAlarmDurationMileage + "" : "--";
				}
			}
		]
	},
	fatigueDayDriveManage: {
		formInput: [{
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "25%"
                }
            },
            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: []
            }, {
			label: "选择时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "持续时间",
				prop: "duration",
				width: utils.setFontSize(150),
				render(row) {
					return row.duration || "--";
				}
			},
			{
				label: "速度",
				prop: "speed",
				width: utils.setFontSize(100),
				render(row) {
					return !isNaN(row.speed)?row.speed+' km/h':"--";
				}
			},
			{
				label: "车辆位置",
				prop: "address",
				width: utils.setFontSize(250),
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "处理状态",
				prop: "handleStatus",
				width: utils.setFontSize(100),
				render(row) {
					let handleStatus = {
						0: "人工待处理",
						1: "人工已处理",
						2: "AI处理",
						3: "误报",
						4: "忽略",
						5: "核警"
					};
					return handleStatus[row.handleStatus] || "--";
				}
			},
			{
				label: "处理方式",
				prop: "idcard",
				width: utils.setFontSize(100),
				render(row) {
					let handleWay = {
						1: "终端提醒",
						2: "短信提醒",
						3: "电话提醒"
					};
					return handleWay[row.handleWay] || "--";
				}
			},
			{
				label: "处理人",
				prop: "handlePeople",
				width: utils.setFontSize(100),
				render(row) {
					return row.handlePeople || "--";
				}
			},
			{
				label: "处理时间",
				prop: "handleTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.handleTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			}, {
				label: "处理内容",
				prop: "handleContent",
				width: utils.setFontSize(120),
				render(row) {
					return row.handleContent || "--";
				}
			},
			{
				label: "处理描述",
				prop: "handleRemark",
				width: utils.setFontSize(150),
				render(row) {
					return row.handleRemark || "--";
				}
			}
		]
	},
	speedDayAlarmManage: {
		formInput: [{
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "25%"
                }
            },
            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: []
            }, {
			label: "选择时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "持续时间",
				prop: "duration",
				width: utils.setFontSize(100),
				render(row) {
					return row.duration || "--";
				}
			},
			{
				label: "速度",
				prop: "speed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.speed || row.speed === 0) ? parseFloat(row.speed.toFixed(1)) + " km/h" : "--";
				}
			},
			{
				label: "车辆位置",
				prop: "address",
				width: utils.setFontSize(180),
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "处理状态",
				prop: "handleStatus",
				width: utils.setFontSize(120),
				render(row) {
					let handleStatus = {
						0: "人工待处理",
						1: "人工已处理",
						2: "AI处理",
						3: "误报",
						4: "忽略",
						5: "核警"
					};
					return handleStatus[row.handleStatus] || "--";
				}
			},
			{
				label: "处理方式",
				prop: "idcard",
				width: utils.setFontSize(100),
				render(row) {
					let handleWay = {
						1: "终端提醒",
						2: "短信提醒",
						3: "电话提醒"
					};
					return handleWay[row.handleWay] || "--";
				}
			},
			{
				label: "处理人",
				prop: "handlePeople",
				width: utils.setFontSize(100),
				render(row) {
					return row.handlePeople || "--";
				}
			},
			{
				label: "处理时间",
				prop: "handleTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.handleTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "处理内容",
				prop: "handleContent",
				width: utils.setFontSize(120),
				render(row) {
					return row.handleContent || "--";
				}
			},
			{
				label: "处理描述",
				prop: "handleRemark",
				width: utils.setFontSize(120),
				render(row) {
					return row.handleRemark || "--";
				}
			}
		]
	},
	fatigueDriverManage: {
		formInput: [{
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "30%"
                }
            },
            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: []
            }, {
			label: "选择时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "30%"
			},
			handler: "startEndTime"
		}, {
			label: "数据来源:",
			type: "select",
			key: "alarmCodes",
			clearable: true,
			options: [{
				label: "全部",
				value: "2,1002"
			}, {
				label: "终端",
				value: "2"
			}, {
				label: "平台",
				value: "1002"
			}],
			style: {
				width: "20%"
			}
		}, {
			label: "持续时长:",
			type: "time",
			popperClass: "monitor-date-picker",
			key: "durationTime",
			style: {
				width: "30%"
			},
			handler: "durationTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "报警总数",
				prop: "alarmNum",
				render(row) {
					return row.alarmNum + "" || "--";
				}
			},
			{
				label: "持续时长",
				prop: "alarmDurationTime",
				render(row) {
					return utils.getDuration(row.alarmDurationTime * 1000) || "--";
				}
			},
			{
				label: "持续里程(km)",
				prop: "alarmDurationMileage",
				render(row) {
					return +row.alarmDurationMileage > -1 ? row.alarmDurationMileage + "" : "--";
				}
			},
			{
				label: "统计日期",
				prop: "statDay",
				// width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.statDay, "YYYY-MM-DD") || "--";
				}
			}
		]
	},
	speedAlarmManage: {
		formInput: [{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "30%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%",
			},
			options: []
		},
		{
			label: "选择时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "30%"
			},
			handler: "startEndTime"
		}, {
			label: "数据来源:",
			type: "select",
			key: "alarmCodes",
			clearable: true,
			options: [{
				label: "全部",
				value: "1,1001"
			}, {
				label: "终端",
				value: "1"
			}, {
				label: "平台",
				value: "1001"
			}],
			style: {
				width: "20%"
			}
		}, {
			label: "持续时长:",
			type: "time",
			popperClass: "monitor-date-picker",
			key: "durationTime",
			style: {
				width: "30%"
			},
			handler: "durationTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "报警总数",
				prop: "alarmNum",
				render(row) {
					return row.alarmNum || "--";
				}
			},
			{
				label: "持续时长",
				prop: "alarmDurationTime",
				render(row) {
					return utils.getDuration(row.alarmDurationTime * 1000) || "--";
				}
			},
			{
				label: "持续里程(km)",
				prop: "alarmDurationMileage",
				render(row) {
					return +row.alarmDurationMileage > -1 ? row.alarmDurationMileage + "" : "--";
				}
			},
			{
				label: "统计日期",
				prop: "statDay",
				render(row) {
					return utils.formatDate(row.statDay, "YYYY-MM-DD") || "--";
				}
			}
		]
	},
	firmOfflineManage: {
		formInput: [{
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			label: "平台名称:",
			type: "input",
			key: "name",
			style: {
				width: "16%"
			}
		}, {
			label: "接入码:",
			type: "input",
			key: "accessCode",
			style: {
				width: "16%"
			}
		}, {
			label: "接入IP:",
			type: "input",
			key: "accessIp",
			style: {
				width: "16%"
			}
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "下级平台名称",
				prop: "name",
				render(row) {
					return row.name || "--";
				}
			},
			{
				label: "接入码",
				prop: "accessCode",
				render(row) {
					return row.name || "--";
				}
			},
			{
				label: "接入IP",
				prop: "accessIP",
				render(row) {
					return row.name || "--";
				}
			},
			{
				label: "在线状态",
				prop: "status",
				render(row) {
					return row.status ? "上线" : "下线";
				}
			},
			{
				label: "创建时间",
				prop: "createTime",
				// width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			}
		]
	},
	alarmDayManage: {
		formInput: [
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "25%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},		
		{
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "16%"
			},
			handler: "startEndTime"
		}, {
			id: "rules_management_alarm_remind_setting_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",

		}],
		listHeader: [{
				label: "运营类型",
				prop: "operationType",
				width: utils.setFontSize(130),
				render(row) {
					return row.operationType ? "运营" : "非运营" || "--";
				}
			},
			{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(200),
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				width: utils.setFontSize(100),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			}, {
				label: "车辆状态",
				prop: "vehicleDayStatus",
				width: utils.setFontSize(130),
				render(row) {
					let vehicleDayStatus = "无数据";
					if (row.vehicleDayStatus == 0) {
						vehicleDayStatus = "离线";
					} else if (row.vehicleDayStatus == 1) {
						vehicleDayStatus = "在线";
					}
					return vehicleDayStatus;
				}
			},
			{
				label: "超速报警次数",
				prop: "speedAlarmNum",
				width: utils.setFontSize(120),
				render(row) {
					return row.speedAlarmNum || "--";
				}
			},
			{
				label: "超时驾驶报警次数",
				prop: "fatigueAlarmNum",
				width: utils.setFontSize(150),
				render(row) {
					return row.fatigueAlarmNum || "--";
				}
			},
			{
				label: "最后速度",
				prop: "lastSpeed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.lastSpeed || row.lastSpeed === 0) ? row.lastSpeed + " km/h" : "--";
				}
			},
			{
				label: "最后定位时间",
				prop: "lastTime",
				width: utils.setFontSize(180),
				render(row) {
					return row.lastTime || "--";
				}
			},
			{
				label: "最后位置",
				prop: "address",
				width: utils.setFontSize(300),
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "纠正率(%)",
				prop: "lastSpeed",
				width: utils.setFontSize(120),
				render(row) {
					return (row.correctRate || row.correctRate === 0) ? parseFloat((row.correctRate * 100).toFixed(
						1)) + "" : "--";
				}
			},
			{
				label: "报警总数",
				prop: "totalAlarmNum",
				width: utils.setFontSize(120),
				render(row) {
					return row.totalAlarmNum || "--";
				}
			}
		]
	},
	// 车辆上线率统计
	vehicleOnlineManage: {
		formInput: [
			// {
			// 	label: "组织/车辆:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "25%"
			// 	}
			// },
			{
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "25%"
                }
            },            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: []
            },
			{
				label: "起止时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				dateType: "daterange",
				key: "startEndTime",
				style: {
					width: "22%"
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "在线时长",
				prop: "onlineTime",
				render(row) {
					if (row.onlineTime === "0") {
						return "0秒";
					} else {
						return utils.getDuration(row.onlineTime * 1000) || "--";
					}
				}
			},
			{
				label: "上线率(%)",
				prop: "onlineRate",
				render(row) {
					let onlineRate = "--";
					if (row.onlineRate && row.onlineRate <= 100 && row.onlineRate >= 0) {
						onlineRate = row.onlineRate;
					}
					return onlineRate;
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			}
		]
	},
	vehOfflineManage: {
		formInput: [
			{
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "25%"
                }
            },
            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: []
            },
			{
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "持续时间",
				prop: "duration",
				render(row) {
					return row.duration || "--";
				}
			},
			{
				label: "车辆位置",
				prop: "address",
				width: utils.setFontSize(180),
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "速度",
				prop: "speed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.speed || row.speed === 0) ? parseFloat((row.speed / 10).toFixed(1)) + " km/h" : "--";
				}
			},
			{
				label: "处理方式",
				prop: "idcard",
				render(row) {
					let handleWay = {
						1: "终端提醒",
						2: "短信提醒",
						3: "电话提醒"
					};
					return handleWay[row.handleWay] || "--";
				}
			},
			{
				label: "处理状态",
				prop: "handleStatus",
				render(row) {
					let handleStatus = {
						0: "人工待处理",
						1: "人工已处理",
						2: "AI处理",
						3: "误报",
						4: "忽略",
						5: "核警"
					};
					return handleStatus[row.handleStatus] || "--";
				}
			},
			{
				label: "处理人",
				prop: "handlePeople",
				render(row) {
					return row.handlePeople || "--";
				}
			},
			{
				label: "处理内容",
				prop: "handleContent",
				width: utils.setFontSize(120),
				render(row) {
					return row.handleContent || "--";
				}
			},
			{
				label: "处理描述",
				prop: "handleRemark",
				width: utils.setFontSize(120),
				render(row) {
					return row.handleRemark || "--";
				}
			}
		]
	},
	mileageDaily: {
		formInput: [
		// {
		// 	label: "组织/车辆:",
		// 	type: "vehicleTree",
		// 	placeholder: "输入进行查询",
		// 	key: "entName",
		// 	style: {
		// 		width: "calc(25% - 60px)",
		// 		marginRight: "20px"
		// 	}
		// }, 
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "25%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%",
			},
			options: [],
			handler: "vid"
		},		
		{
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "18%",
			},
			handler: "startEndTime"
		}, {
			id: "rules_management_alarm_remind_setting_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(120),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				width: utils.setFontSize(100),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "行驶里程(km)",
				prop: "drivingMileage",
				width: utils.setFontSize(120),
				render(row) {
					return (row.drivingMileage || row.drivingMileage === 0) ? parseFloat(row.drivingMileage.toFixed(
						1)) + "" : "--";
				}
			},
			{
				label: "开始里程(km)",
				prop: "startMileage",
				width: utils.setFontSize(120),
				render(row) {
					return utils.getIntOrFloat(row.startMileage);
				}
			},
			{
				label: "结束里程(km)",
				prop: "endMileage",
				width: utils.setFontSize(120),
				render(row) {
					return utils.getIntOrFloat(row.endMileage);
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				width: utils.setFontSize(200),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				width: utils.setFontSize(200),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "开始位置",
				prop: "startAddress",
				width: utils.setFontSize(300),
				render(row) {
					return row.startAddress || "--";
				}
			},
			{
				label: "结束位置",
				prop: "endAddress",
				width: utils.setFontSize(300),
				render(row) {
					return row.endAddress || "--";
				}
			}
		]
	},
	mileageMonthly: {
		formInput: [
		// {
		// 	label: "组织/车辆:",
		// 	type: "vehicleTree",
		// 	placeholder: "输入进行查询",
		// 	key: "entName",
		// 	style: {
		// 		width: "calc(25% - 60px)",
		// 		marginRight: "20px"
		// 	}
		// },
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "25%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%",
				marginRight: "20px"
			},
			options: [],
			handler: "vid"
		},		
		{
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startTime",
			dateType: "month",
			format: "yyyy-MM",
			style: {
				width: "18%"
			},
			handler: "startTime"
		}, {
			id: "rules_management_alarm_remind_setting_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "行驶里程(km)",
				prop: "drivingMileage",
				render(row) {
					return utils.getIntOrFloat(row.drivingMileage);
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			}
		]
	},
	mileageHarvester: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: [],
				handler: "vid"
            },			
			{
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			clearable: false,	
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			id: "data_center_mileage_harvester_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [
			{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(120),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				width: utils.setFontSize(100),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "车身宽度(米)",
				prop: "vehicleWidth",
				width: utils.setFontSize(150)
			},	
			{
				label: "工作面积(亩)",
				prop: "workArea",
				render(row) {
					return row.workArea==undefined? "--":row.workArea;
				}
			},					
			{
				label: "开始时间",
				prop: "startTime",
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "开始里程(km)",
				prop: "startMileage",
				render(row) {
					return utils.getIntOrFloat(row.startMileage);
				}
			},
			{
				label: "结束里程(km)",
				prop: "endMileage",
				render(row) {
					return utils.getIntOrFloat(row.endMileage);
				}
			},
			{
				label: "工作里程(km)",
				prop: "workMileage",
				render(row) {
					return utils.getIntOrFloat(row.workMileage);
				}
			}


		]
	},

	// 轨迹完整率
	tarckRateManage: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},            {
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "20%"
				},
				options: []
			},
			{
				label: "起止时间:",
				type: "dateRange",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				style: {
					width: "28%"
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "连续里程(km)",
				prop: "continuousMileage",
				render(row) {
					return row.continuousMileage || "0";
				}
			},
			{
				label: "不连续里程(km)",
				prop: "",
				render(row) {	
					let noMileage = (row.totalMileage - row.driftMileage - row.continuousMileage).toFixed(2);
					return Number(noMileage) <= 0 ? '0' : noMileage.toFixed(2);
				}
			},
			{
				label: "漂移里程(km)",
				prop: "driftMileage",
				render(row) {
					return row.driftMileage || "0";
				}
			},
			{
				label: "总里程(km)",
				prop: "totalMileage",
				render(row) {
					return row.totalMileage || "0";
				}
			},
			{
				label: "轨迹完整率(%)",
				prop: "trackCompleteRate",
				isClick: true,
				triggerCondition: 100,
				render(row) {
					return row.trackCompleteRate || row.trackCompleteRate === 0 ? row.trackCompleteRate + "" : "--";
					// let trackCompleteRate = row.trackCompleteRate || row.trackCompleteRate === 0 ? row.trackCompleteRate : "--";
					// let className = trackCompleteRate != 100 && trackCompleteRate != '--'?'table-click': ''
					// return `<span class="${className}">${trackCompleteRate}</span>`
				}
			}
		],
		listHeader2: [
			{
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				isCenter: true,
			},	
			{
				label: "车牌颜色",
				prop: "vehiclePlateColorStr",
				width: utils.setFontSize(90),
				isCenter: true,
			},		
			{
				label: "不连续里程(km)",
				prop: "vehiclePlateColorStr",
				width: utils.setFontSize(160),
				isCenter: true,
				render(row){
					let endMileage = ~~row.endMileage,startMileage=~~row.startMileage;
					return ((endMileage-startMileage)/10).toFixed(1);
				}
			},				
			{
				label: "起点GPS时间",
				prop: "startTime",
				width: utils.setFontSize(200),
				isCenter: true,
				render(row) {
					return utils.formatDate(+row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "起点GPS速度(公里/小时)",
				prop: "startSpeed",
				width: utils.setFontSize(200),
				isCenter: true,
				render(row) {
					return row.startSpeed>0?(row.startSpeed/10).toFixed(1):'0';
				}
			},
			{
				label: "终点GPS时间",
				prop: "endTime",
				width: utils.setFontSize(200),
				isCenter: true,
				render(row) {
					return utils.formatDate(+row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "终点GPS速度(公里/小时)",
				prop: "endSpeed",
				width: utils.setFontSize(200),
				isCenter: true,
				render(row) {
					return row.endSpeed>0?(row.endSpeed/10).toFixed(1):'0';
				}
			},			
			{
				label: "轨迹完整率详情",
				prop: "driftCount",
				render(row) {
					let driftCount = "--";
					if (row.driftType == 1) {
						driftCount = "连续" + row.driftCount + "个定位点，两点经纬度里程差大于10km";
					} else {
						driftCount = "连续" + row.driftCount + "个定位点，连续5段两点经纬度里程差大于2km且小于10km";
					}
					return driftCount;
				}
			}
		]
	},
	vehOnOffDetailManage: {
		formInput: [
			// {
			// 	label: "组织/车辆:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%",
				},
				options: [],
				handler: "vid"
			},			
			{
				label: "起止时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				dateType: "daterange",
				key: "startEndTime",
				style: {
					width: "24%",
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "持续时长",
				prop: "durationTime",
				render(row) {
					let durationTime = "--";
					if (row.durationTime == 0) {
						durationTime = "0秒";
					} else {
						durationTime = utils.getDuration(row.durationTime * 1000) || "--";
					}
					return durationTime;
				}
			},
			// {
			// 	label: "在线状态",
			// 	prop: "onlineTime",
			// 	render(row) {
			// 		return row.onlineStatus ? "在线" : "离线" || "--";
			// 	}
			// },
			{
				label: "车辆上线时间",
				prop: "startTime",
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "车辆下线时间",
				prop: "endTime",
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			}
		]
	},
	offlineVehicleManage: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "22%",
				},
				options: [],
				handler: "vid",
				multiple: true
			},			
			{
				label: "离线时长:",
				type: "select",
				popperClass: "monitor-select",
				key: "offLineTime",
				style: {
					width: "15%"
				},
				options: [{
					label: "自定义",
					value: 0
				}, {
					label: "三天",
					value: 3
				}, {
					label: "七天",
					value: 7
				}, {
					label: "一个月",
					value: 31
				}],
				handler: "offLineTime"
			}, {
				label: "",
				type: "input",
				key: "customDay",
				inputType: "number",
				style: {
					width: "6%"
				},
				disabled: true,
				unit: "天"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
				style:{
					marginLeft: '0.5rem'
				}
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(220),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "离线时长",
				prop: "offlineTime",
			},
			{
				label: "最后定位时间",
				prop: "lastGpsTime",
			},
			{
				label: "经度",
				prop: "mapLon"
			},
			{
				label: "纬度",
				prop: "mapLat"
			},			
			{
				label: "地理位置",
				prop: "address",
				render(row) {
					return row.address || "--";
				}
			}

		]
	},	
	vehOfflineStaticManage: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%"
				},
				options: [],
				handler: "vid"
			},
			// {
			//     label: "起止时间:",
			//     type: "datePicker",
			//     popperClass: "monitor-date-picker",
			//     dateType: "daterange",
			//     key: "startEndTime",
			//     style: {
			//         width: "calc(25% - 40px)"
			//     },
			//     handler: "startEndTime"
			// },
			{
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "车主联系手机号",
				prop: "ownerPhone",
				render(row) {
					return row.ownerPhone || "--";
				}
			}
		]
	},
	// 车辆上下线统计
	vehOnOffStaticManage: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%",
				},
				options: [],
				handler: "vid"
			},				
			{
				label: "起止时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				dateType: "daterange",
				key: "startEndTime",
				style: {
					width: "24%",
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "在线总时长",
				prop: "onlineTime",
				render(row) {
					if (row.onlineTime < 0) {
						return "--";
					}
					return utils.getDuration(row.onlineTime * 1000) || "--";
				}
			},
			{
				label: "离线总时长",
				prop: "offlineTime",
				render(row) {
					if (row.offlineTime < 0) {
						return "--";
					}
					return utils.getDuration(row.offlineTime * 1000) || "--";
				}
			},
			{
				label: "在线率(%)",
				prop: "onlineRate",
				render(row) {
					let onlineRate = "--";
					if (row.onlineRate && row.onlineRate <= 100 && row.onlineRate >= 0) {
						onlineRate = row.onlineRate;
					}
					return onlineRate;
				}
			},
			{
				label: "车辆上线时间",
				prop: "startTime",
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "车辆下线时间",
				prop: "endTime",
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			}
		]
	},
	vehMonthStaticManage: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%",
				},
				options: [],
				handler: "vid"
			},			
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startTime",
				dateType: "month",
				format: "yyyy-MM",
				style: {
					width: "18%",
				},
				handler: "startTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(120),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				width: utils.setFontSize(120),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "月在线率(%)",
				prop: "onlineRate",
				width: utils.setFontSize(150),
				render(row) {
					let onlineRate = "--";
					if (row.onlineRate && row.onlineRate <= 100 && row.onlineRate >= 0) {
						onlineRate = row.onlineRate;
					}
					return onlineRate;
				}
			},
			{
				label: "月在线天数",
				prop: "onlineDayNum",
				width: utils.setFontSize(120),
				render(row) {
					return (row.onlineDayNum || row.onlineDayNum === 0) ? row.onlineDayNum + "" : "--";
				}
			},
			{
				label: "1日",
				prop: "dayOne",
				tooltip: true,
				render(row) {
					return row.dayOne == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "2日",
				prop: "dayTwo",
				tooltip: true,
				render(row) {
					return row.dayTwo == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "3日",
				prop: "dayThree",
				tooltip: true,
				render(row) {
					return row.dayThree == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "4日",
				prop: "dayFour",
				tooltip: true,
				render(row) {
					return row.dayFour == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "5日",
				prop: "dayFive",
				tooltip: true,
				render(row) {
					return row.dayFive == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "6日",
				prop: "daySix",
				tooltip: true,
				render(row) {
					return row.daySix == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "7日",
				prop: "daySeven",
				tooltip: true,
				render(row) {
					return row.daySeven == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "8日",
				prop: "dayEight",
				tooltip: true,
				render(row) {
					return row.dayEight == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "9日",
				prop: "dayNine",
				tooltip: true,
				render(row) {
					return row.dayNine == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "10日",
				prop: "dayTen",
				tooltip: true,
				render(row) {
					return row.dayTen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "11日",
				prop: "dayEleven",
				tooltip: true,
				render(row) {
					return row.dayEleven == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "12日",
				prop: "dayTwelve",
				tooltip: true,
				render(row) {
					return row.dayTwelve == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "13日",
				prop: "dayThirteen",
				tooltip: true,
				render(row) {
					return row.dayThirteen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "14日",
				prop: "dayFourteen",
				tooltip: true,
				render(row) {
					return row.dayFourteen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "15日",
				prop: "dayFifteen",
				tooltip: true,
				render(row) {
					return row.dayFifteen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "16日",
				prop: "daySixteen",
				tooltip: true,
				render(row) {
					return row.daySixteen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "17日",
				prop: "daySeventeen",
				tooltip: true,
				render(row) {
					return row.daySeventeen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "18日",
				prop: "dayEighteen",
				tooltip: true,
				render(row) {
					return row.dayEighteen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "19日",
				prop: "dayNineteen",
				tooltip: true,
				render(row) {
					return row.dayNineteen == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "20日",
				prop: "dayTwenty",
				tooltip: true,
				render(row) {
					return row.dayTwenty == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "21日",
				prop: "dayTwentyOne",
				tooltip: true,
				render(row) {
					return row.dayTwentyOne == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "22日",
				prop: "dayTwentyTwo",
				tooltip: true,
				render(row) {
					return row.dayTwentyTwo == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "23日",
				prop: "dayTwentyThree",
				tooltip: true,
				render(row) {
					return row.dayTwentyThree == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "24日",
				prop: "dayTwentyFour",
				tooltip: true,
				render(row) {
					return row.dayTwentyFour == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "25日",
				prop: "dayTwentyFive",
				tooltip: true,
				render(row) {
					return row.dayTwentyFive == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "26日",
				prop: "dayTwentySix",
				tooltip: true,
				render(row) {
					return row.dayTwentySix == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "27日",
				prop: "dayTwentySeven",
				tooltip: true,
				render(row) {
					return row.dayTwentySeven == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "28日",
				prop: "dayTwentyEight",
				tooltip: true,
				render(row) {
					return row.dayTwentyEight == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "29日",
				prop: "dayTwentyNine",
				tooltip: true,
				render(row) {
					return row.dayTwentyNine == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "30日",
				prop: "dayThirty",
				tooltip: true,
				render(row) {
					return row.dayThirty == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			},
			{
				label: "31日",
				prop: "dayThirtyOne",
				tooltip: true,
				render(row) {
					return row.dayThirtyOne == 1 ? `<span class='iconfont iconduihao11'> </span>` : "--";
				}
			}
		]
	},
	// 企业车辆在线统计
	comMonthStaticManage: {
		formInput: [
			// {
			// 	label: "组织名称:",
			// 	type: "orgTree",
			// 	key: "entName",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%",
				}
			},	
			{
				label: "起止时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				dateType: "month",
				format: "yyyy-MM",
				key: "startTime",
				style: {
					width: "18%",
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车辆总数",
				prop: "totalCount",
				render(row) {
					return row.totalCount + "";
				}
			},
			{
				label: "在线数",
				prop: "onlineCount",
				render(row) {
					return row.onlineCount + "";
				}
			},
			{
				label: "离线数",
				prop: "offlineCount",
				render(row) {
					return row.offlineCount + "";
				}
			},
			{
				label: "上线率(%)",
				prop: "onlineRate",
				render(row) {
					let onlineRate = "--";
					if (row.onlineRate && row.onlineRate <= 100 && row.onlineRate >= 0) {
						onlineRate = row.onlineRate;
					}
					return onlineRate;
				}
			}
		]
	},
	drivingStaticManage: {
		formInput: [
			// {
			// 	label: "车牌号码:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "16%"
				},
				options: []
			},			
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				dateType: "date",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			},
			{
				label: "行车时长超过:",
				type: "input",
				key: "duration",
				unit: "分",
				style: {
					width: "12%"
				},
				handler: "duration"
			},
			{
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(180),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				width: utils.setFontSize(100),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "行驶里程(km)",
				prop: "drivingMileage",
				width: utils.setFontSize(130),
				render(row) {
					return (row.drivingMileage || row.drivingMileage === 0) ? parseFloat((row.drivingMileage / 10)
						.toFixed(1)) + "" : "--";
				}
			},
			{
				label: "行驶时长",
				prop: "duration",
				width: utils.setFontSize(150),
				render(row) {
					return utils.getDuration(row.duration) || "--";
				}
			},
			{
				label: "最高速度",
				prop: "maxSpeed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.maxSpeed || row.maxSpeed === 0) ? parseFloat((row.maxSpeed / 10).toFixed(1)) + " km/h" :
						"--";
				}
			},
			{
				label: "平均速度",
				prop: "avgSpeed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.avgSpeed || row.avgSpeed === 0) ? parseFloat((row.avgSpeed / 10).toFixed(1)) + " km/h" :
						"--";
				}
			},
			{
				label: "行程是否结束",
				prop: "overTrip",
				width: utils.setFontSize(150),
				render(row) {
					return row.overTrip === 0 ? "未结束" : "已结束";
				}
			},
			{
				label: "开始位置",
				prop: "startAddress",
				width: utils.setFontSize(250),
				tooltip: true,
				render(row) {
					return row.startAddress || "--";
				}
			},
			{
				label: "结束位置",
				prop: "endAddress",
				width: utils.setFontSize(250),
				tooltip: true,
				render(row) {
					return row.endAddress || "--";
				}
			}
		]
	},
	parkingStaticManage: {
		formInput: [
			// {
			// 	label: "车牌号码:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%"
				},
				options: []
			},			
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				dateType: "date",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			},
			{
				label: "停车时长超过:",
				type: "input",
				key: "duration",
				unit: "分",
				style: {
					width: "12%",
					marginRight: '20px'
				},
				handler: "duration"
			},
			{
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "开始时间",
				prop: "startTime",
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "结束时间",
				prop: "endTime",
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "停车时长",
				prop: "duration",
				render(row) {
					return utils.getDuration(row.duration) || "--";
				}
			},
			{
				label: "行程是否结束",
				prop: "overTrip",
				render(row) {
					return row.overTrip === 0 ? "未结束" : "已结束";
				}
			},
			{
				label: "停车位置",
				prop: "address",
				render(row) {
					return row.address || "--";
				}
			}
		]
	},
	driveCollect: {
		formInput: [
			// {
			// 	label: "组织/车辆:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%"
				},
				options: []
			},			
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				dateType: "date",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(200),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "统计行驶开始点时间",
				prop: "startTime",
				width: utils.setFontSize(200),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "统计行驶结束点时间",
				prop: "endTime",
				width: utils.setFontSize(200),
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "行驶持续时长",
				prop: "duration",
				render(row) {
					return utils.getDuration(row.duration) || "--";
				}
			},
			{
				label: "行驶次数",
				prop: "nunTimes",
				render(row) {
					return (row.nunTimes || row.nunTimes === 0) || "--";
				}
			},
			{
				label: "行驶里程(km)",
				prop: "drivingMileage",
				render(row) {
					return (row.drivingMileage || row.drivingMileage === 0) ? parseFloat((row.drivingMileage / 10)
						.toFixed(1)) : "--";
				}
			},
			{
				label: "平均速度",
				prop: "avgSpeed",
				render(row) {
					return (row.avgSpeed || row.avgSpeed === 0) ? parseFloat((row.avgSpeed / 10).toFixed(1))+' km/h' : "--";
				}
			},
			{
				label: "最高速度",
				prop: "maxSpeed",
				render(row) {
					return (row.maxSpeed || row.maxSpeed === 0) ? parseFloat((row.maxSpeed / 10).toFixed(1))+' km/h' : "--";
				}
			}
		]
	},
	parkCollect: {
		formInput: [
			// {
			// 	label: "组织/车辆:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%"
				},
				options: []
			},			
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				dateType: "date",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "停车次数",
				prop: "nunTimes",
				render(row) {
					return row.nunTimes || "--";
				}
			},
			{
				label: "停车开始点时间",
				prop: "startTime",
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "停车结束点时间",
				prop: "endTime",
				render(row) {
					return utils.formatDate(row.endTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "停车持续时长",
				prop: "duration",
				render(row) {
					return utils.getDuration(row.duration) || "--";
				}
			}
		]
	},
	vehicleTrack: {
		formInput: [
			// {
			// 	label: "车牌号码:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "vehicleNo",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// }, 
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%"
				},
				options: [],
				handler: "vid"
			},	
	
			{
				label: "GPS速度:",
				type: "input",
				key: "gpsSpeed",
				style: {
					width: "15%"
				},
				handler: "gpsSpeedInput"
			},
			{
				label: "起止时间:",
				type: "dateRange",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				style: {
					width: "28%",
				},
				handler: "startEndTime"
			},
			{
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(200),
				render(row) {
					return row.entName + "";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(150),
				render(row) {
					return row.vehicleNo || "";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				width: utils.setFontSize(120),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "车辆状态",
				prop: "baseStateName",
				width: utils.setFontSize(220),
				render(row) {
					return row.baseStateName || "";
				}
			},
			{
				label: "GPS时间",
				prop: "gpsTime",
				width: utils.setFontSize(200),
				render(row) {
					return utils.formatDate(row.gpsTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "系统时间",
				prop: "systemTime",
				width: utils.setFontSize(200),
				render(row) {
					return utils.formatDate(row.systemTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "GPS速度",
				prop: "gpsSpeed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.gpsSpeed || row.gpsSpeed === 0) ? parseFloat((row.gpsSpeed / 10).toFixed(1)) + " km/h" :
						"--";
				}
			},
			{
				label: "方向",
				prop: "head",
				render(row) {
					return utils.getDriection(row.head);
				}
			},
			{
				label: "总里程(km)",
				prop: "mileage",
				width: utils.setFontSize(120),
				render(row) {
					return utils.getIntOrFloat(row.mileage);
				}
			},
			{
				label: "经度",
				prop: "mapLon",
				width: utils.setFontSize(150),
				render(row) {
					return (row.mapLon / 600000).toFixed(5) || "--";
				}
			},
			{
				label: "纬度",
				prop: "mapLat",
				width: utils.setFontSize(150),
				render(row) {
					return (row.mapLat / 600000).toFixed(5) || "--";
				}
			},
			{
				label: "停留时长",
				prop: "stopTime",
				width: utils.setFontSize(200),
				render(row) {
					return row.stopTime || "--";
				}
			},
			{
				label: "报警状态",
				prop: "alarmNames",
				width: utils.setFontSize(130),
				render(row) {
					return row.alarmNames || "--";
				}
			},
			{
				label: "地理位置",
				prop: "address",
				width: utils.setFontSize(400),
				render(row) {
					return row.address || "--";
				}
			}
		]
	},
	offlineStatistics: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},
			{
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "18%"
				},
				options: [],
				handler: 'vid'
			},			
			{
				label: "离线时长:",
				type: "select",
				popperClass: "monitor-select",
				key: "lastPositionTimeSelect",
				style: {
					width: "15%"
				},
				options: [{
					label: "自定义",
					value: "1"
				}, {
					label: "三天",
					value: "2"
				}, {
					label: "七天",
					value: "3"
				}, {
					label: "一个月",
					value: "4"
				}],
				handler: "lastPositionTimeChange"
			}, {
				label: "",
				type: "input",
				key: "customDay",
				inputType: "number",
				style: {
					width: "8%"
				},
				disabled: true,
				unit: "天"
			}, {
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
				style:{
					marginLeft: '0.5rem'
				}
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "车主联系电话",
				prop: "ownerPhone",
				render(row) {
					return row.ownerPhone || "--";
				}
			},
			{
				label: "未定位时长",
				prop: "offlineTime",
				render(row) {
					return utils.getDuration(row.offlineTime) || "--";
				}
			},
			{
				label: "末次汇报时间",
				prop: "lastPositionTime",
				render(row) {
					return utils.formatDate(row.lastPositionTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "末次上报位置",
				prop: "endTime",
				width: utils.setFontSize(300),
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "未定位原因",
				prop: "untargetedReason",
				render(row) {
					return row.untargetedReason || "--";
				}
			}
		]
	},
	inspectionNotice: {
		formInput: [{
			label: "回复状态",
			type: "select",
			popperClass: "monitor-select",
			key: "answerStatus",
			options: [{
				label: "全部",
				value: ""
			}, {
				label: "用户不在线",
				value: "0"
			}, {
				label: "已推送未回复",
				value: "1"
			}, {
				label: "回复取消或拒绝",
				value: "2"
			}, {
				label: "回复确定",
				value: "3"
			}],
			style: {
				width: "16%"
			}
		}, {
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			id: "data_center_regulatory_chagang_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "平台服务标识",
				prop: "serviceId",
				render(row) {
					return row.serviceId || "--";
				}
			},
			{
				label: "平台接入码",
				prop: "accessCode",
				render(row) {
					return row.accessCode || "--";
				}
			},
			{
				label: "查岗信息ID",
				prop: "inforId",
				render(row) {
					return row.inforId || "--";
				}
			},
			{
				label: "巡检内容",
				prop: "infor",
				render(row) {
					return row.infor || "--";
				}
			},
			{
				label: "平台用户账号",
				prop: "loginAccount",
				width: utils.setFontSize(150),
				render(row) {
					return row.loginAccount || "--";
				}
			},
			{
				label: "巡检信息推送时间",
				prop: "pushTime",
				render(row) {
					return utils.formatDate(row.pushTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "巡检回复时间",
				prop: "answerTime",
				render(row) {
					return utils.formatDate(row.answerTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "巡检回复状态",
				prop: "answerStatus",
				render(row) {
					let answerStatus = "--";
					if (row.answerStatus == 0) {
						answerStatus = "用户不在线";
					} else if (row.answerStatus == 1) {
						answerStatus = "已推送未回复";
					} else if (row.answerStatus == 2) {
						answerStatus = "回复取消或拒绝";
					} else if (row.answerStatus == 3) {
						answerStatus = "回复确定";
					}
					return answerStatus;
				}
			},
			{
				label: "巡检回复内容",
				prop: "answerContent",
				width: utils.setFontSize(180),
				render(row) {
					return row.answerContent;
				}
			}
		]
	},
	activeSafe: {
		formInput: [
		{
		    label: "组织名称:",
		    type: "orgTree",
		    key: "entName",
		    itemType: "filterItem",
		    style: {
		        width: "30%"
		    }
		},
		{
		    label: "车牌号码:",
		    type: "searchinputv2",
		    key: "vid",
		    style: {
		        width: "18%"
		    },
			options: []
		},
		{
			label: "报警类型:",
			type: "alarmTree",
			key: "alarmCode",
			category: 1,
			style: {
				width: "20%"
			}
		}, {
			label: "文件类型:",
			type: "select",
			popperClass: "monitor-select",
			key: "attachFormat",
			style: {
				width: "20%"
			},
			options: [{
				label: "全部",
				value: ""
			}, {
				label: "图片",
				value: 0
			}, {
				label: "视频",
				value: 2
			}],
			handler: "attachFormat"
		}, {
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			pickerOptions: { //只能选择当前之前的日期，并且是同一天日期
				shortcuts: [{
					text: "近五分钟",
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 5 * 60 * 1000);
						picker.$emit("pick", [start, end]);
					}
				}, {
					text: "近十分钟",
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 10 * 60 * 1000);
						picker.$emit("pick", [start, end]);
					}
				}]
			},
			style: {
				width: "30%"
			},
			handler: "startEndTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "平台服务标识",
				prop: "serviceId",
				render(row) {
					return row.serviceId || "--";
				}
			},
			{
				label: "平台接入码",
				prop: "accessCode",
				render(row) {
					return row.accessCode || "--";
				}
			},
			{
				label: "查岗信息ID",
				prop: "inforId",
				render(row) {
					return row.inforId || "--";
				}
			},
			{
				label: "巡检内容",
				prop: "infor",
				render(row) {
					return row.infor || "--";
				}
			},
			{
				label: "平台用户账号",
				prop: "loginAccount",
				width: utils.setFontSize(150),
				render(row) {
					return row.loginAccount || "--";
				}
			},
			{
				label: "巡检信息推送时间",
				prop: "pushTime",
				render(row) {
					return utils.formatDate(row.pushTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "巡检回复时间",
				prop: "answerTime",
				render(row) {
					return utils.formatDate(row.answerTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "巡检回复状态",
				prop: "answerStatus",
				render(row) {
					let answerStatus = "--";
					if (row.answerStatus == 0) {
						answerStatus = "用户不在线";
					} else if (row.answerStatus == 1) {
						answerStatus = "已推送未回复";
					} else if (row.answerStatus == 2) {
						answerStatus = "回复取消或拒绝";
					} else if (row.answerStatus == 3) {
						answerStatus = "回复确定";
					}
					return answerStatus;
				}
			},
			{
				label: "巡检回复内容",
				prop: "answerContent",
				width: utils.setFontSize(180),
				render(row) {
					return row.answerContent;
				}
			}
		]
	},
	instructIssue: {
		formInput: [
		// {
		// 	label: "组织/车辆:",
		// 	type: "vehicleTree",
		// 	placeholder: "输入进行查询",
		// 	key: "entName",
		// 	style: {
		// 		width: "calc(25% - 60px)",
		// 		marginRight: "20px"
		// 	}
		// }, 
		{
		    label: "组织名称:",
		    type: "orgTree",
		    key: "entName",
		    itemType: "filterItem",
		    style: {
		        width: "22%",
		        marginRight: "10px"
		    }
		},
		{
		    label: "车牌号码:",
		    type: "searchinputv2",
		    key: "vid",
			handler: 'vid',
		    style: {
		        width: "18%"
		    },
			options: []
		},		
		{
			label: "通道:",
			type: "select",
			popperClass: "monitor-select",
			key: "channelNums",
			multiple: true,
			collapseTags: true,
			style: {
				width: "15%"
			},
			options: [],
			handler: "channelNums"
		}, {
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			pickerOptions: {
				shortcuts: [{
					text: "近五分钟",
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 5 * 60 * 1000);
						picker.$emit("pick", [start, end]);
					}
				}, {
					text: "近十分钟",
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 10 * 60 * 1000);
						picker.$emit("pick", [start, end]);
					}
				}]
			},
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "平台服务标识",
				prop: "serviceId",
				render(row) {
					return row.serviceId || "--";
				}
			},
			{
				label: "平台接入码",
				prop: "accessCode",
				render(row) {
					return row.accessCode || "--";
				}
			},
			{
				label: "查岗信息ID",
				prop: "inforId",
				render(row) {
					return row.inforId || "--";
				}
			},
			{
				label: "巡检内容",
				prop: "infor",
				render(row) {
					return row.infor || "--";
				}
			},
			{
				label: "平台用户账号",
				prop: "loginAccount",
				width: utils.setFontSize(150),
				render(row) {
					return row.loginAccount || "--";
				}
			},
			{
				label: "巡检信息推送时间",
				prop: "pushTime",
				render(row) {
					return utils.formatDate(row.pushTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "巡检回复时间",
				prop: "answerTime",
				render(row) {
					return utils.formatDate(row.answerTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "巡检回复状态",
				prop: "answerStatus",
				render(row) {
					let answerStatus = "--";
					if (row.answerStatus == 0) {
						answerStatus = "用户不在线";
					} else if (row.answerStatus == 1) {
						answerStatus = "已推送未回复";
					} else if (row.answerStatus == 2) {
						answerStatus = "回复取消或拒绝";
					} else if (row.answerStatus == 3) {
						answerStatus = "回复确定";
					}
					return answerStatus;
				}
			},
			{
				label: "巡检回复内容",
				prop: "answerContent",
				width: utils.setFontSize(180),
				render(row) {
					return row.answerContent;
				}
			}
		]
	},
	driverRisk: {
		formInput: [
		// 	{
		// 	label: "车牌号码:",
		// 	type: "vehicleTree",
		// 	placeholder: "输入进行查询",
		// 	key: "entName",
		// 	style: {
		// 		width: "22%"
		// 	}
		// }, 
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "25%"
			}
		},            {
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},
		{
			label: "起止时间:",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			style: {
				width: "28%"
			},
			handler: "startEndTime"
		}, {
			id: "data_center_analysis_driver",
			auth: "data_center_analysis_driver",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}, {
			id: "data_center_analysis_driver_export",
			auth: "data_center_analysis_driver_export",
			label: "",
			text: "导出",
			type: "button",
			key: "export",
			itemType: "primary",
			handler: "export",
		}]
	},
	vehicleRisk: {
		formInput: [
		// {
		// 	label: "车牌号码:",
		// 	type: "vehicleTree",
		// 	placeholder: "输入进行查询",
		// 	key: "entName",
		// 	style: {
		// 		width: "22%"
		// 	}
		// }, 
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "25%"
			}
		},            {
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},
		
		{
			label: "时间范围:",
			type: "select",
			popperClass: "monitor-select",
			key: "statType",
			style: {
				width: "14%"
			},
			options: [{
				label: "日",
				value: "DAY"
			}, {
				label: "周",
				value: "WEEK"
			}, {
				label: "月",
				value: "MONTH"
			}],
			handler: "statType"
		}, {
			label: "起止时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			pickerOptions: {
				firstDayOfWeek: 1
			},
			style: {
				width: "18%"
			},
			handler: "startEndTime"
		}, {
			id: "data_center_analysis_vehicle",
			auth: "data_center_analysis_vehicle",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}, {
			id: "data_center_analysis_vehicle_export",
			auth: "data_center_analysis_vehicle_export",
			label: "",
			text: "导出",
			type: "button",
			key: "export",
			itemType: "primary",
			handler: "export",
		}]
	},
	entRisk: {
		formInput: [
		// 	{
		// 	label: "组织名称:",
		// 	type: "saasTree4",
		// 	placeholder: "请选择组织",
		// 	key: "entName",
		// 	style: {
		// 		width: "22%"
		// 	}
		// }, 
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "25%"
			}
		},  		
		{
			label: "时间范围:",
			type: "select",
			popperClass: "monitor-select",
			key: "statType",
			style: {
				width: "14%"
			},
			options: [{
				label: "日",
				value: "DAY"
			}, {
				label: "周",
				value: "WEEK"
			}, {
				label: "月",
				value: "MONTH"
			}],
			handler: "statType"
		}, {
			label: "起止时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			pickerOptions: {
				firstDayOfWeek: 1
			},
			style: {
				width: "18%"
			},
			handler: "startEndTime"
		}, {
			id: "data_center_analysis_ent",
			auth: "data_center_analysis_ent",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}, {
			id: "data_center_analysis_ent_export",
			auth: "data_center_analysis_ent_export",
			label: "",
			text: "导出",
			type: "button",
			key: "export",
			itemType: "primary",
			handler: "export",

		}]
	},
	comMonthAlarmManage: {
		formInput: [{
				label: "组织名称:",
				type: "saasTree4",
				placeholder: "请选择组织",
				key: "entName",
				style: {
					width: "22%"
				}
			},
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startTime",
				dateType: "month",
				format: "yyyy-MM",
				style: {
					width: "18%"
				},
				handler: "startTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车辆总数",
				prop: "totalVehicleNum",
				render(row) {
					return +row.totalVehicleNum > -1 ? row.totalVehicleNum : "--";
				}
			},
			{
				label: "报警类型",
				prop: "alarmCode",
				// width: utils.setFontSize(120),
				render(row) {
					let allAlarmType = store.state.allAlarmType;
					return allAlarmType[row.alarmCode] || "--";
				}
			},
			{
				label: "报警总数",
				prop: "totalAlarmNum",
				render(row) {
					return +row.totalAlarmNum > -1 ? "" + row.totalAlarmNum : "--";
				}
			},
			{
				label: "纠正率(%)",
				prop: "correctRate",
				render(row) {
					return +row.correctRate > -1 ? "" + row.correctRate : "--";
				}
			},
			{
				label: "纠正总数",
				prop: "totalAlarmCorrectNum",
				render(row) {
					return +row.totalAlarmCorrectNum > -1 ? "" + row.totalAlarmCorrectNum : "--";
				}
			}
		]
	},
	comMonthReportManage: {
		formInput: [{
				label: "组织名称:",
				type: "saasTree4",
				placeholder: "请选择组织",
				key: "entName",
				style: {
					width: "22%"
				}
			},
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startTime",
				dateType: "month",
				format: "yyyy-MM",
				style: {
					width: "18%"
				},
				handler: "startTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				// width: utils.setFontSize(120),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				// width: utils.setFontSize(120),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				// width: utils.setFontSize(120),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "报警类型",
				prop: "alarmName",
				// width: utils.setFontSize(120),
				render(row) {
					let allAlarmType = store.state.allAlarmType;
					return allAlarmType[row.alarmCode] || "--";
				}
			},
			{
				label: "报警总数",
				prop: "totalAlarmNum",
				// width: utils.setFontSize(120),
				render(row) {
					return row.totalAlarmNum || "0";
				}
			}
		]
	},
	smsissuedManage: {
		formInput: [
			// {
			// 	label: "组织/车辆:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "22%"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},            {
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "20%"
				},
				options: []
			},			
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				dateType: "date",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			},
			{
				label: "通知状态:",
				type: "select",
				popperClass: "monitor-select",
				key: "notifyStatus",
				style: {
					width: "14%"
				},
				options: [{
					label: "全部",
					value: ""
				}, {
					label: "失败",
					value: 0
				}, {
					label: "成功",
					value: 1
				}]
			},
			{
				label: "通知类型:",
				type: "select",
				popperClass: "monitor-select",
				key: "notifyType",
				style: {
					width: "15%"
				},
				options: []
			},
			{
				label: "通知模板:",
				type: "select",
				popperClass: "monitor-select",
				key: "templateId",
				style: {
					width: "15%"
				},
				options: []
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
				style: {
					// height: "34px",
					// marginLeft: '10px'
				}
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				// width: utils.setFontSize(200),
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				// width: utils.setFontSize(120),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "模板名称",
				prop: "templateName",
				// width: utils.setFontSize(120),
				render(row) {
					return row.templateName || "--";
				}
			},
			{
				label: "车主姓名",
				prop: "ownerName",
				// width: utils.setFontSize(120),
				render(row) {
					return row.ownerName || "--";
				}
			},
			{
				label: "车主电话",
				prop: "ownerPhone",
				// width: utils.setFontSize(120),
				render(row) {
					return row.ownerPhone || "--";
				}
			},
			{
				label: "通知内容",
				prop: "notifyContent",
				// width: utils.setFontSize(120),
				render(row) {
					return row.notifyContent || "--";
				}
			},
			{
				label: "备注",
				prop: "remark",
				// width: utils.setFontSize(120),
				render(row) {
					return row.remark || "--";
				}
			},
			{
				label: "通知状态",
				prop: "notifyStatus",
				// width: utils.setFontSize(120),
				render(row) {
					return row.notifyStatus == 0 ? "失败" : "成功";
				}
			},
			{
				label: "发送时间",
				prop: "createTime",
				// width: utils.setFontSize(120),
				render(row) {
					return utils.formatDate(row.createTime, "YYYY-MM-DD hh:mm:ss");
				}
			}
		]
	},
	thirdPartyManage: {
		formInput: [{
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "25%"
                }
            },
            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: []
            }, {
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "18%"
			},
			handler: "startEndTime"
		}, {
			id: "rules_management_alarm_remind_setting_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "运营类型",
				prop: "operationType",
				width: utils.setFontSize(130),
				render(row) {
					return row.operationType ? "运营" : "非运营" || "--";
				}
			},
			{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(200),
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor",
				width: utils.setFontSize(100),
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			}, {
				label: "车辆状态",
				prop: "vehicleDayStatus",
				width: utils.setFontSize(130),
				render(row) {
					let vehicleDayStatus = "无数据";
					if (row.vehicleDayStatus == 0) {
						vehicleDayStatus = "离线";
					} else if (row.vehicleDayStatus == 1) {
						vehicleDayStatus = "在线";
					}
					return vehicleDayStatus;
				}
			},
			{
				label: "超速报警次数",
				prop: "speedAlarmNum",
				width: utils.setFontSize(120),
				render(row) {
					return row.speedAlarmNum || "--";
				}
			},
			{
				label: "超时驾驶报警次数",
				prop: "fatigueAlarmNum",
				width: utils.setFontSize(150),
				render(row) {
					return row.fatigueAlarmNum || "--";
				}
			},
			{
				label: "最后速度",
				prop: "lastSpeed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.lastSpeed || row.lastSpeed === 0) ? row.lastSpeed + " km/h" : "--";
				}
			},
			{
				label: "最后定位时间",
				prop: "lastTime",
				width: utils.setFontSize(180),
				render(row) {
					return row.lastTime || "--";
				}
			},
			{
				label: "最后位置",
				prop: "address",
				width: utils.setFontSize(300),
				render(row) {
					return row.address || "--";
				}
			},
			{
				label: "纠正率(%)",
				prop: "lastSpeed",
				width: utils.setFontSize(120),
				render(row) {
					return (row.correctRate || row.correctRate === 0) ? parseFloat((row.correctRate * 100).toFixed(
						1)) + "" : "--";
				}
			},
			{
				label: "报警总数",
				prop: "totalAlarmNum",
				width: utils.setFontSize(120),
				render(row) {
					return row.totalAlarmNum || "--";
				}
			}
		]
	},
	guangdongManage: {
		formInput: [{
                label: "组织名称:",
                type: "orgTree",
                key: "entName",
                itemType: "filterItem",
                style: {
                    width: "25%"
                }
            },
            {
                label: "车牌号码:",
                type: "searchinputv2",
                key: "vid",
                style: {
                    width: "20%"
                },
				options: []
            }, {
			label: "选择时间:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "startEndTime",
			dateType: "date",
			style: {
				width: "18%"
			},
			handler: "startEndTime"
		}, {
			id: "rules_management_alarm_remind_setting_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "报警时间(巡查时间)",
				prop: "startTime",
				width: utils.setFontSize(200),
				render(row) {
					return utils.formatDate(row.startTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}

			}, {
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}

			}, {
				label: "速度",
				prop: "speed",
				width: utils.setFontSize(100),
				render(row) {
					return (row.speed || row.speed === 0) ? parseFloat((row.speed / 10).toFixed(1)) + " km/h" : "--";
				}

			}, {
				label: "最后位置",
				prop: "address",
				width: utils.setFontSize(300),
				render(row) {
					return row.address || "--";
				}

			}, {
				label: "智能视频监控平台报警及巡查监控发现的问题",
				prop: "intelligentVideo",
				isCenter: true,
				width: utils.setFontSize(300),
				children: [{
					label: "未系安全带",
					prop: "alarmCode",
					tooltip: true,
					width: utils.setFontSize(130),
					render(row) {
						return row.alarmCode == 96 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}, {
					label: "双手脱离方向盘",
					prop: "alarmCode",
					tooltip: true,
					width: utils.setFontSize(150),
					render(row) {
						return row.alarmCode == 99 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}, {
					label: "设备遮挡",
					prop: "alarmCode",
					tooltip: true,
					width: utils.setFontSize(150),
					render(row) {
						return row.alarmCode == 84 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}, {
					label: "打哈欠",
					prop: "alarmCode",
					tooltip: true,
					width: utils.setFontSize(150),
					render(row) {
						return row.alarmCode == 83 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}, {
					label: "接打电话",
					prop: "alarmCode",
					tooltip: true,
					width: utils.setFontSize(180),
					render(row) {
						return row.alarmCode == 80 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}, {
					label: "分神驾驶",
					prop: "alarmCode",
					tooltip: true,
					width: utils.setFontSize(120),
					render(row) {
						return row.alarmCode == 90 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}]

			}, {
				label: "卫星定位监控平台报警",
				prop: "satellite",
				isCenter: true,
				width: utils.setFontSize(300),
				children: [{
					label: "超速报警",
					prop: "alarmCode",
					tooltip: true,
					width: utils.setFontSize(120),
					render(row) {
						return row.alarmCode == 1 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}, {
					label: "超时驾驶报警",
					prop: "fatigueDrive",
					tooltip: true,
					width: utils.setFontSize(120),
					render(row) {
						return row.alarmCode == 2 ? "<span class='iconfont iconiconfontcheck'></span>" :
							"<span class='iconfont iconguanbi'></span>";
					}
				}]
			},
			{
				label: "处理措施",
				prop: "handleWay",
				width: utils.setFontSize(120),
				render(row) {
					let handleWay = {
						1: "808 809终端",
						2: "短信",
						3: "电话",
						4: "企业安全员短信",
						5: "企业安全员电话"
					};
					return handleWay[row.handleWay] || "--";
				}
			},
			{
				label: "处理结果",
				prop: "totalAlarmNum",
				width: utils.setFontSize(120),
				render(row) {
					return row.totalAlarmNum || "--";
				}
			}
		]
	},
	// 企业入网率
	accessRateManage: {
		formInput: [{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				style: {
					width: "25%"
				}
			},
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "总车辆数",
				prop: "totalVehCount",
				render(row) {
					return row.totalVehCount || "0";
				}
			},
			{
				label: "入网数",
				prop: "continuousMileage",
				render(row) {
					return row.accessNetCount || "0";
				}
			},
			{
				label: "未入网数",
				prop: "noaccessNetCount",
				render(row) {
					return row.noaccessNetCount || "0";
				}
			},
			{
				label: "入网率(%)",
				prop: "netRate",
				render(row) {
					return row.netRate || "0";
				}
			}, {
				label: "统计日期",
				prop: "statDay",
				render(row) {
					return row.statDay || "--";
					// return utils.formatDate(row.statDay, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			}
		]
	},
	// 车辆数据合格率
	dataPassRateManage: {
		formInput: [
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},            {
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "20%"
				},
				options: []
			},
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			}, {
				label: "合格率:",
				type: "select",
				popperClass: "monitor-select",
				key: "symbol",
				options: [{
					label: "≥",
					value: "1"
				}, {
					label: "≤",
					value: "0"
				}],
				style: {
					width: "14%"
				},
				handler: "handleQuaRate"
			}, {
				label: "",
				type: "input",
				key: "qualifiedRate",
				style: {
					width: "6%"
				},
				unit: "%"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
				style: {
					marginLeft: '0.5rem'
				}
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(200),
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车牌号",
				prop: "vehicleNo",
				width: utils.setFontSize(100),
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				width: utils.setFontSize(100),
				prop: "vehiclePlateColor",
				render(row) {
					let vehiclePlateColorList = JSON.parse(localStorage.getItem("vehiclePlateColorArr"));
					let vehiclePlateColorArr = vehiclePlateColorList.filter(item => item.value === row
						.vehiclePlateColor);
					return vehiclePlateColorArr.length && vehiclePlateColorArr[0].label || "";
				}
			},
			{
				label: "轨迹总数",
				width: utils.setFontSize(120),
				prop: "totalGpsCount",
				render(row) {
					return row.totalGpsCount || "0";
				}
			},
			{
				label: "合格轨迹数",
				width: utils.setFontSize(150),
				prop: "qualifiedGpsCount",
				render(row) {
					return row.qualifiedGpsCount || "0";
				}
			},
			{
				label: "不合格轨迹数",
				prop: "unqualifiedGpsCount",
				width: utils.setFontSize(180),
				render(row) {
					return row.unqualifiedGpsCount || "0";
				}
			},
			{
				label: "合格率(%)",
				prop: "qualifiedRate",
				width: utils.setFontSize(100),
				render(row) {
					return row.qualifiedRate || "0";
				}
			},
			{
				label: "卫星时间不合格数",
				prop: "unqualifiedGpsTimeCount",
				isClick: true,
				triggerCondition: 0,
				width: utils.setFontSize(180),
				render(row) {
					return row.unqualifiedGpsTimeCount || "0";
				}
			},
			{
				label: "经度不合格数",
				prop: "unqualifiedMapLonCount",
				isClick: true,
				triggerCondition: 0,
				width: utils.setFontSize(150),
				render(row) {
					return row.unqualifiedMapLonCount || "0";
				}
			},
			{
				label: "纬度不合格数",
				prop: "unqualifiedMapLatCount",
				isClick: true,
				triggerCondition: 0,
				width: utils.setFontSize(150),
				render(row) {
					return row.unqualifiedMapLatCount || "0";
				}
			},
			{
				label: "速度不合格数",
				prop: "unqualifiedGpsSpeedCount",
				isClick: true,
				triggerCondition: 0,
				width: utils.setFontSize(150),
				render(row) {
					return row.unqualifiedGpsSpeedCount || "0";
				}
			},
			{
				label: "海拔不合格数",
				prop: "unqualifiedAltitudeCount",
				isClick: true,
				triggerCondition: 0,
				width: utils.setFontSize(150),
				render(row) {
					return row.unqualifiedAltitudeCount || "0";
				}
			},
			{
				label: "方向不合格数",
				prop: "unqualifiedHeadCount",
				isClick: true,
				triggerCondition: 0,
				width: utils.setFontSize(150),
				render(row) {
					return row.unqualifiedHeadCount || "0";
				}
			}
		],
		listHeader2: [{
				label: "上报时间",
				prop: "gpsTime",
				width: utils.setFontSize(180),
				render(row) {
					return utils.formatDate(+row.gpsTime, "YYYY-MM-DD hh:mm:ss") || "--";
				}
			},
			{
				label: "错误原因",
				prop: "errorDescribe",
				render(row) {
					return row.errorDescribe || "--";
				}
			}
		]
	},
	connectRateManage: {
		formInput: [
			// {
			// 	label: "组织/车辆:",
			// 	type: "vehicleTree",
			// 	placeholder: "输入进行查询",
			// 	key: "entName",
			// 	style: {
			// 		width: "calc(25% - 60px)",
			// 		marginRight: "20px"
			// 	}
			// },
			{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				itemType: "filterItem",
				style: {
					width: "25%"
				}
			},            {
				label: "车牌号码:",
				type: "searchinputv2",
				key: "vid",
				style: {
					width: "20%"
				},
				options: []
			},			
			{
				label: "起止时间:",
				type: "dateRange",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				style: {
					width: "calc(30% - 20px)",
					marginRight: "0rem"
				},
				handler: "startEndTime"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
				style: {
					position: "absolute",
					right: "20px",
					top: "0"
				}
			}
		],
		listHeader: [{
				label: "接入平台",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "正常连接时长",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "总时长",
				prop: "continuousMileage",
				render(row) {
					return row.continuousMileage || "0";
				}
			},
			{
				label: "平台连通率(%)",
				prop: "trackCompleteRate",
				render(row) {
					return row.trackCompleteRate || "--";
				}
			}
		]
	},
	driftRateManage: {
		formInput: [{
				label: "组织名称:",
				type: "orgTree",
				key: "entName",
				style: {
					width: "25%"
				}
			},
			{
				label: "选择时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "startEndTime",
				style: {
					width: "18%"
				},
				handler: "startEndTime"
			}, {
				label: "合格率",
				type: "select",
				popperClass: "monitor-select",
				key: "symbol",
				options: [{
					label: "<=",
					value: "0"
				}, {
					label: ">=",
					value: "1"
				}],
				style: {
					width: "14%"
				}
			}, {
				label: "",
				type: "input",
				key: "driftRate",
				style: {
					width: "6%"
				},
				unit: "%"
			}, {
				id: "rules_management_alarm_remind_setting_query",
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
				style: {
					marginLeft: '0.5rem'
				}
			}
		],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			},
			{
				label: "车辆总数",
				prop: "totalVehicleCount",
				render(row) {
					return row.totalVehicleCount || "0";
				}
			},
			{
				label: "上线车辆数",
				prop: "onlineVehicleCount",
				render(row) {
					return row.onlineVehicleCount || "0";
				}
			},
			{
				label: "漂移总数",
				prop: "totalDriftCount",
				render(row) {
					return row.totalDriftCount || "0";
				}
			},
			{
				label: "漂移车辆数",
				prop: "driftVehicleCount",
				render(row) {
					return row.driftVehicleCount || "0";
				}
			},
			{
				label: "漂移率(%)",
				prop: "driftRate",
				render(row) {
					return row.driftRate || "0";
				}
			}
		]
	},
	safeWebRisk: {
		formInput: [
			{
				label: "组织名称:",
				type: "saasTree4",
				placeholder: "请选择组织",
				key: "entName",
				style: {
					width: "30%",
				}
			}, {
				label: "时间范围:",
				type: "select",
				popperClass: "monitor-select",
				key: "statType",
				style: {
					width: "16%",
				},
				clearable: true,
				options: [{
					label: "日",
					value: "DAY"
				}, {
					label: "周",
					value: "WEEK"
				}, {
					label: "月",
					value: "MONTH"
				}],
				handler: "statType"
			},{
				label: "时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "day",
				format: 'yyyy-MM-dd',
				dateType: "date",
				clearable: false,
				pickerOptions:{
					firstDayOfWeek: 1,
					disabledDate(time) {
						return time.getTime() > Date.now() - 8.64e7;
					}
				},
				style: {
					width: "16%",
				},
				handler: "day"
			},{
				label: "时间:",
				type: "datePickerWeek",
				popperClass: "monitor-date-picker",
				key: "week",
				format: 'yyyy 第 WW 周',
				dateType: "week",
				clearable: false,
				pickerOptions:{
					firstDayOfWeek: 1,
					disabledDate(time) {
						//return time.getTime() >= Date.now();
						return time.getTime() > Date.now() - 8.64e7;
					}
				},
				style: {
					width: "16%",
					display: 'none'
				},
				handler: "weekDate"
			},{
				label: "时间:",
				type: "datePicker",
				popperClass: "monitor-date-picker",
				key: "month",
				dateType: "month",
				clearable: false,
				pickerOptions:{
					firstDayOfWeek: 1,
					disabledDate(time) {
                        var date = new Date();
                        var year = date.getFullYear();
                        var month = date.getMonth() + 1;
                        if (month >= 1 && month <= 9) {
                            month = "0" + month;
                        }
                        var currentdate = year.toString()  + month.toString();
 
                        var timeyear = time.getFullYear();
                        var timemonth = time.getMonth() + 1;
                        if (timemonth >= 1 && timemonth <= 9) {
                            timemonth = "0" + timemonth;
                        }
                        var timedate = timeyear.toString() + timemonth.toString();
                        return currentdate <= timedate;
					}
				},				
				style: {
					width: "16%",
					display: 'none'
				},
				handler: "weekDate"
			}, {
				label: "统计项:",
				type: "select",
				popperClass: "monitor-select",
				key: "states",
				multiple: true,
				collapseTags: true,			
				style: {
					width: "30%"
				},
				options: [{
					label: "车辆上线率",
					value: "车辆上线率"
				}, {
					label: "轨迹完整率",
					value: "轨迹完整率"
				}, {
					label: "数据合格率",
					value: "数据合格率"
				}, {
					label: "平均超速次数",
					value: "平均超速次数"
				}, {
					label: "平均超时时长",
					value: "平均超时时长"
				},{
					label: "卫星定位漂移率",
					value: "卫星定位漂移率"
				}],
				handler: "states"
			}, {
				label: "排行显示条目:",
				type: "select",
				popperClass: "monitor-select",
				key: "limit",
				style: {
					width: "30%",
				},
				options: [ {
					label: "top5",
					value: 5
				}, {
					label: "top10",
					value: 10
				}, {
					label: "top20",
					value: 20
				}, {
					label: "top50",
					value: 50
				}, {
					label: "top100",
					value: 100
				}],
				handler: "top"
			},{
				id: "data_center_analysis_safewebrisk", 
				auth: "data_center_analysis_safewebrisk", 
				label: "",
				text: "查询",
				type: "button",
				key: "search",
				itemType: "primary",
				handler: "search",
			}, {
				id: "data_center_analysis_safewebrisk_export",
				auth: "data_center_analysis_safewebrisk_export",// data_center_analysis_safewebrisk_export
				label: "",
				text: "导出",
				type: "button",
				key: "export",
				itemType: "primary",
				handler: "export",
			}
		],
		overspeedTableHeader: [
			{
				label: "序号",
				prop: "number",
				width: utils.setFontSize(80),
				isCenter: true
			},			
			{
				label: "组织名称",
				prop: "entName",
				isCenter: true
			},
			{
				label: "车牌号",
				prop: "vehicleNo",				
				isCenter: true
			},
			{
				label: "报警次数",
				prop: "alarmCount",
				width: utils.setFontSize(120),
				isCenter: true
			},
			{
				label: "上周期报警次数",
				prop: "lastAlarmCount",
				width: utils.setFontSize(160),
				isCenter: true
			},
		],
		completeRateRankTableHeader: [
			{
				label: "序号",
				prop: "number",
				width: utils.setFontSize(80),
				isCenter: true
			},	
			{
				label: "组织名称",
				prop: "entName",
				isCenter: true
			},
			{
				label: "车牌号",
				prop: "vehicleNo",				
				isCenter: true
			},{
				label: "总里程",
				prop: "totalMileage",				
				isCenter: true
			},{
				label: "漂移里程",		
				prop: "driftMileage",				
				isCenter: true
			},{
				label: "完整率(%)",	
				prop: "competeRate",				
				isCenter: true
			},{
				label: "上周期轨迹完整率(%)",		
				prop: "lastCompeteRate",				
				isCenter: true
			}			
		],
		qualifiedRateRankTableHeader:[
			{
				label: "序号",
				prop: "number",
				width: utils.setFontSize(80),
				isCenter: true
			},	
			{
				label: "组织名称",
				prop: "entName",
				isCenter: true
			},
			{
				label: "车牌号",
				prop: "vehicleNo",				
				isCenter: true
			},{
				label: "数据总数",	
				prop: "totalGpsCount",				
				isCenter: true
			},{
				label: "合格数据数",		
				prop: "qualifiedGpsCount",				
				isCenter: true
			},{
				label: "数据合格率(%)",		
				prop: "qualifiedRate",				
				isCenter: true
			},{	
				label: "上周期(%)",		
				prop: "lastQualifiedRate",				
				isCenter: true,
				render(row) {
					return row.lastQualifiedRate +'%' || "--";
				}
			}			
		],
		vehicleOfflineRankTableHeader: [
			{
				label: "序号",
				prop: "number",
				width: utils.setFontSize(80),
				isCenter: true
			},	
			{
				label: "组织名称",
				prop: "entName",
				isCenter: true
			},
			{
				label: "车牌号",
				prop: "vehicleNo",				
				isCenter: true
			},
			{
				label: "离线时间",
				prop: "lastPositionTime",				
				isCenter: true
			},
			{
				label: "离线位置",
				prop: "lastLocation",				
				isCenter: true
			}
		],
		overtimeRankTableHeader:[
			{
				label: "序号",
				prop: "number",
				width: utils.setFontSize(80),
				isCenter: true
			},	
			{
				label: "组织名称",
				prop: "entName",
				width: utils.setFontSize(300),
				isCenter: true
			},
			{
				label: "车牌号",
				prop: "vehicleNo",				
				isCenter: true
			},
			{
				label: "本期超时驾驶累计次数",
				prop: "alarmCount",				
				isCenter: true
			},
			{
				label: "上周期累计次数",
				prop: "lastAlarmCount",				
				isCenter: true
			},			
			{
				label: "本期超时驾驶累计时长",
				prop: "alarmDuration",
				isCenter: true
			},
			{
				label: "上周期超时驾驶累计时长",
				prop: "lastAlarmDuration",
				isCenter: true
			}
		],
		vehicleDriftRankTableHeader:[
			{
				label: "序号",
				prop: "number",
				width: utils.setFontSize(80),
				isCenter: true
			},	
			{
				label: "组织名称",
				prop: "entName",
				isCenter: true
			},
			{
				label: "车牌号",
				prop: "vehicleNo",				
				isCenter: true
			},
			{
				label: "飘移次数",
				prop: "driftCount",
				width: utils.setFontSize(160),
				isCenter: true
			},
			{
				label: "上周期",
				prop: "lastDriftCount",
				width: utils.setFontSize(160),
				isCenter: true
			}
		]		
	},
	handoverManage: {
		formInput: [
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "400px"
			}
		},
		{
			label: "选择日期:",
			key: "startEndTime",
			type: "dateRange",
			popperClass: "monitor-date-picker",
			format:"yyyy-MM-dd HH:mm",
			style: {
				width: '460px'
			},
			handler: "startEndTime"
		}, 
		{
			id: "data_center_handover_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
	},

	// 车辆月报警统计
	vehicleAlarmMonthPage: {
		formInput: [
		{
			label: "组织名称:",
			type: "orgTree",
			key: "entName",
			itemType: "filterItem",
			style: {
				width: "25%"
			}
		},
		{
			label: "车牌号码:",
			type: "searchinputv2",
			key: "vid",
			style: {
				width: "20%"
			},
			options: []
		},		
		{
			label: "选择月份:",
			type: "datePicker",
			popperClass: "monitor-date-picker",
			key: "statMonth",
			dateType: "month",
			placeholder: '统计月份',
			format:'yyyy-MM',
			style: {
				width: "16%"
			},
			handler: "statMonth"
		}, {
			id: "base_info_org_query",
			label: "",
			text: "查询",
			type: "button",
			key: "search",
			itemType: "primary",
			handler: "search",
		}],
		listHeader: [{
				label: "组织名称",
				prop: "entName",
				render(row) {
					return row.entName || "--";
				}
			}, {
				label: "车牌号",
				prop: "vehicleNo",
				render(row) {
					return row.vehicleNo || "--";
				}
			},
			{
				label: "车牌颜色",
				prop: "vehiclePlateColor"
			},
			{
				label: "车辆类型",
				prop: "vehicleType"
			},
			{
				label: "超速报警数量",
				prop: "overspeedCount"
			},
			{
				label: "超时报警数量",
				prop: "overtimeCount"
			},
			{
				label: "超时预警数量",
				prop: "overtimeEarlyCount"
			},
			{
				label: "报警合计",
				prop: "totalAlarmCount"
			},
			{
				label: "统计月份",
				prop: "statMonth",
				render(row){
					return row.statMonth.substr(0,4)+'-'+row.statMonth.substr(4,6);
				}
			}
		]
	},



};
